<!--<ng-container>-->
<!--  <div class="room-min-height bg-white rounded-3xl">-->


<!--    <div *ngIf="selectedChat" class="flex align-item-center p-3 bg-gray-100 rounded-t-3xl">-->

<!--      <button (click)="toggle()" *ngIf="toggleButton"-->
<!--              class="sm:block md:hidden lg:hidden xl:hidden rounded text-center text-white bg-indigo-500 mt-1 px-2.5 py-1.5 text-md font-semibold shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">-->
<!--        <i class="fa fa-backspace mr-2 ml-1"></i>-->
<!--        {{ 'back' | translate }}-->
<!--      </button>-->

<!--      <div *ngIf="selectedChat" class="chat-avatar padding-room  ml-2">-->
<!--        <div *ngIf="chatAccountIndex >= 0 ">-->
<!--          <img alt="img" class="img-fluid"-->
<!--               onerror="this.src='assets/images/defaults/default-user.png';"-->
<!--               src="{{environment.filePath + selectedConversation.accounts[chatAccountIndex].profilePic}}" />-->
<!--        </div>-->
<!--      </div>-->

<!--      <div *ngIf="selectedChat" class="flex-grow-1 ml-2">-->
<!--        <div *ngIf="chatAccountIndex >= 0 "-->
<!--             class="f-20 f-w-600">{{ selectedConversation.accounts[chatAccountIndex].nickName }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div *ngIf="!roomId" class="empty-chat">
      {{ 'select_room'| translate }}
    </div>

<!--    <div #scrollContainer *ngIf="roomId" class="flex-grow overflow-y-auto" style="height: calc(100% - 9.5rem);">-->
<div class="bg-white mb-5" *ngIf="selectedConversation">
  <app-scrollable-wall (load)="loadMore()" [height]="'calc(100vh - 215px)'"  className="with-padding">
  <div class="flex flex-col mt-5 px-2">
    <div *ngFor="let messege of selectedConversation.messages">
      <app-message-item [in]="!(messege?.sender?.id === customUserId)" [item]="messege"></app-message-item>
    </div>
  </div>
  </app-scrollable-wall>
</div>

<!--    </div>-->

    <section #inputMessage *ngIf="selectedChat" class="border rounded-xl bg-gray-50 mb-3">
      <textarea (keyup.enter)="sendMessage()" [(ngModel)]="newMessage"
                class="w-full bg-gray-50 p-2 rounded-xl resize-none" placeholder="Введите что-нибудь..."
                rows="3"></textarea>
      <div class="flex items-center justify-between p-2">
        <button (click)="sendMessage()" class="bg-blue-500 text-white px-6 py-2 rounded-xl">
          {{'send'|translate}}
        </button>
      </div>
    </section>


    <!--      <input type="text" (keyup.enter)="sendMessage()" [(ngModel)]="newMessage" placeholder="Введите что-нибудь.." class="w-full rounded-lg border border-gray-300 px-4 py-2" />-->
    <!--      <button class="ml-2 rounded-lg bg-blue-500 px-4 py-2 text-white button" (click)="sendMessage()">-->
    <!--        <div class="svg-wrapper-1">-->
    <!--          <div class="svg-wrapper">-->
    <!--            <i class="fa fa-paper-plane text-white"></i>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </button>-->
<!--  </div>-->


<!--</ng-container>-->
