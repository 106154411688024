import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../../helpers/network.helper';

@Injectable()
export class AnnouncementService {

  private getUrl= 'announcement';
  private myAnnouncementUrl= 'me/announcements';
  private getByIdUrl= 'announcement/:id';
  private getByIdPublicUrl= 'announcement/public/:id';
  private updateUrl= 'announcement/:id';
  private deletedUrl="announcement/:id";
  private acceptUrl="announcement/accept/:id";
  private rejectUrl="announcement/reject/:id";
  private getUserAnnouncementsUrl="announcement/user/:id";

  private getProductInfoUrl = "product-url";

  private getAddressesUrl = "locations";


  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) { }

  getItems(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUrl, {...pagination,...filter});
  }
  getById(id:string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getByIdUrl.replace(':id',id));
  }
  getByIdPublic(id:string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getByIdPublicUrl.replace(':id',id));
  }
  update(id:string, data:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateUrl.replace(':id',id),data);
  }
  deleted(id:string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deletedUrl.replace(':id',id));
  }
  create(data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.getUrl,data );
  }
  createNewForm(data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.getUrl,data );
  }

  accept(id:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.acceptUrl.replace(':id',id) );
  }

  reject(id:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.rejectUrl.replace(':id',id) );
  }

  myAnnoncements(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.myAnnouncementUrl, {...pagination,...filter});
  }

  getUserAnnouncements(pagination: any, id:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUserAnnouncementsUrl.replace(':id',id),{...pagination} );
  }

  getProductInfo(link: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.getProductInfoUrl,{link} );
  }

  getAddresses(myPoint:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.getAddressesUrl,{...myPoint});
  }

}
