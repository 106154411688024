// angular import
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../../../shared/shared.module";
import { RouterLink } from '@angular/router';

// project import

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterLink],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {}
