// angular import
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {AuthenticationService} from "../../../../services/services/authentication.service";
import {WithdrawService} from "../../../../services/services/transaction/withdraw.service";
import {UserService} from "../../../../services/services/user/user.service";
import {MeService} from "../../../../services/services/user/me.service";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {StorageService} from "../../../../services/helpers/storage.service";
import {UserWithdrawComponent} from "../../../../pages/admin/user/user-list/user-withdraw/user-withdraw.component";
import {UserTopupComponent} from "../../../../pages/admin/user/user-list/user-topup/user-topup.component";
import {CustomerRole} from "../../../../services/constants";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-nav-right-guard',
  templateUrl: './toolbar-right-guard.component.html',
  standalone: true,
  imports: [
    NgClass
  ],
  styleUrls: ['./toolbar-right-guard.component.scss']
})
export class NavRightGuardComponent implements OnInit {
  // public props
  @Output() HeaderBlur = new EventEmitter();
  userObj:any;
  profileObj:any;
  env = environment;
  user: any;
  // constructor
  public notSeenMessagesCount: any;
  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    translate.setDefaultLang('ru');


  }


  ngOnInit(): void {

  }
  redirectTo(url:string){
    this.router.navigate(['/auth', url])

  }

}
