
<div class="relative-container">
  <a class="text-wrap_link" [routerLink]="['../announcement/details/',item.id]"  href="javascript:">
    <div class="product-img">
      <img alt="product-img"  src="{{ item.product.image }}"/>
    </div>
    <div class="product-content">
        <div class="prod-price">
          <div class="grid grid-cols-2">
            <div class="text-left">Цена:</div>
            <div class="text-right">{{ item.product.price | translate }} {{ item.product.currency | translate }}</div>
          </div>
        </div>
        <div class=" prod-price ">
          <span>Зароботок:  {{ item.maximalEarning || 0 }} {{ item.product.currency | translate }}</span>
        </div>
        <div class=" prod-price">
          <span>Кешбек: {{ item.cashbackAmount || 0 }} {{ item.product.currency | translate }}</span>
        </div>
        <div class="prod-price">
          <span>{{ item?.account?.nickName }}/{{ item.product.name }}</span>
        </div>
        <div class="prod-price">
          <span  *ngIf="item?.account?.sellerRating">Рейтинг: <i
            class="fa fa-star"></i> {{ item?.account?.sellerRating.toFixed(0) }}</span>
          <span *ngIf="!item?.account?.sellerRating" style="color: orange;">{{'new_freelancer'|translate}}</span>
        </div>
    </div>
  </a>
</div>

















<!--<div class="relative-container">-->
<!--  &lt;!&ndash;              <a [routerLink]="['/profile',item?.account?.id, 'seller','reviews']" class="profile-pic">&ndash;&gt;-->
<!--  &lt;!&ndash;                                                  <img alt="product-img" class="wid-30 hei-30"&ndash;&gt;-->
<!--  &lt;!&ndash;                                                       src="{{ item?.account?.profilePic ? (environment.filePath + item.account.profilePic):'assets/images/user/avatar-2.png'}}"/>&ndash;&gt;-->
<!--  &lt;!&ndash;              </a>&ndash;&gt;-->
<!--  <a [routerLink]="['../announcement/details/',item.id]" class="text-wrap-link" href="javascript:">-->
<!--    <div class="product-img">-->
<!--      <img alt="product-img" class="img-prod img-fluid wid-300 h-300" src="{{ item.product.image }}"/>-->
<!--    </div>-->
<!--    <div class="product-content">-->
<!--      &lt;!&ndash;                                      <div class="prod-name text-muted product-name" >{{ item.product.name }}</div>&ndash;&gt;-->
<!--      <div class="align-items-center justify-content-between m-t-10">-->
<!--        <div class="f-14 prod-price bigger">-->
<!--          <b>Зароботок:</b> {{ item.maximalEarning || 0 }} {{ item.product.currency | translate }}-->
<!--        </div>-->
<!--        <div class="f-14  prod-price bigger">-->
<!--          <b>Кешбек:</b> {{ item.cashbackAmount || 0 }} {{ item.product.currency | translate }}-->
<!--        </div>-->
<!--        <div class="f-14 prod-price">-->
<!--          <div class="small-f">{{ item?.account?.nickName }}/ {{ item.product.name }}</div>-->
<!--          <b>Рейтинг:</b>-->
<!--          <span *ngIf="item?.account?.sellerRating" style="color: orange; margin-left: 10px"><i-->
<!--            class="fa fa-star"></i> {{ item?.account?.sellerRating.toFixed(0) }}</span>-->
<!--          <span *ngIf="!item?.account?.sellerRating"-->
<!--                style="color: orange; margin-left: 10px">Новичок</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </a>-->
<!--</div>-->





<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->


