// angular import
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// angular material import
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// third party import
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TranslateModule } from '@ngx-translate/core';

// project import
import { CardComponent } from 'src/app/services/components/card/card.component';
import {InputAutocompleteComponent} from "./components/input-autocomplete/input-autocomplete.component";
import { ChipAutocompleteComponent } from './components/chip-autocomplete/chip-autocomplete.component';
import {ImageWrapperComponent} from "./components/image-wrapper/image-wrapper.component";
import {DialogDataExampleDialogComponent} from "./components/modal-dialog/modal-dialog.component";
import { PaginationCustomComponent } from './components/pagination-custom/pagination-custom.component';
import { CustomerRoleComponent } from './components/customer-role/customer-role.component';
import { AnnouncementItemComponent } from './components/announcement-item/announcement-item.component';
import {RouterLink} from "@angular/router";
import { ReviewComponent } from './components/review/review.component';
import { ReviewItemComponent } from './components/review/review-item/review-item.component';
import { ClickViewComponent } from './components/click-view/click-view.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationsWidgetComponent } from './components/notifications-widget/notifications-widget.component';
import {NewReviewComponent} from "./components/review/new-review/new-review.component";
import {StarRatingComponent} from "../services/components/star-rating/star-rating.component";
import {NetworkHelper} from "../services/helpers/network.helper";
import {PopupHelper} from "../services/helpers/popup.helper";
import {MeService} from "../services/services/user/me.service";
import {NotificationService} from "../services/services/notification.service";
import {ReviewsService} from "../services/services/review/reviews.service";
import {OrderService} from "../services/services/order/order.service";
import {RoundCurrencyPipe} from "./components/round-currency.pipe";
import {ScrollableWallComponent} from "./components/scrollable-wall/scrollable-wall.component";

const MaterialModules = [
  MatToolbarModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatButtonModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatInputModule,
  MatGridListModule,
  MatCardModule,
  MatListModule,
  MatSlideToggleModule,
  MatBadgeModule,
  MatRadioModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatDialogModule,
  MatNativeDateModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatTooltipModule,
  MatSelectModule,
  CdkAccordionModule,
  MatDatepickerModule,
  MatTreeModule,
  DragDropModule,
  LayoutModule,
  MatStepperModule,
  MatTableModule,
  MatAutocompleteModule,
  MatChipsModule,
  MatSnackBarModule,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModules,
    FormsModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    HttpClientModule,
    TranslateModule,
    CardComponent,
    RouterLink,
    StarRatingComponent,
    ScrollableWallComponent,
  ],
  providers:[
    NetworkHelper,
    PopupHelper,
    MeService,
    NotificationService,
    ReviewsService,
    RoundCurrencyPipe,
    OrderService
  ],
  declarations:[
    InputAutocompleteComponent,
    NewReviewComponent,
    ChipAutocompleteComponent,
    RoundCurrencyPipe,
    ImageWrapperComponent,
    DialogDataExampleDialogComponent,
    PaginationCustomComponent,
    CustomerRoleComponent,
    ReviewComponent,
    ReviewItemComponent,
    AnnouncementItemComponent,
    ClickViewComponent,
    NotificationItemComponent,
    NotificationsWidgetComponent,
  ],
    exports: [
        MaterialModules,
        FormsModule,
        RoundCurrencyPipe,
        ReactiveFormsModule,
        NgScrollbarModule,
        HttpClientModule,
        TranslateModule,
        CardComponent,
        InputAutocompleteComponent,
        ChipAutocompleteComponent,
        NewReviewComponent,
        ImageWrapperComponent,
        DialogDataExampleDialogComponent,
        PaginationCustomComponent,
        CustomerRoleComponent,
        AnnouncementItemComponent,
        ClickViewComponent,
        NotificationsWidgetComponent,
        ReviewComponent,
        ReviewItemComponent,
        NotificationItemComponent
    ]
})
export class SharedModule {}
