<mat-card [ngClass]="cardClass">
  <mat-card-header *ngIf="showHeader" [ngClass]="headerClass"
                   class="card-header justify-content-between align-item-center"
                    style="background-color: {{header_bg_color}}">
    <div class="f-16 f-w-600 p-10 title " [ngClass]="headerTitleClass">
      {{ cardTitle }}
      <ng-container *ngTemplateOutlet="headerTitleTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="headerOptionsTemplate"></ng-container>
  </mat-card-header>
  <mat-card-content *ngIf="showContent" [style.padding.px]="padding"  style="background-color: {{body_bg_color}}" [ngClass]="bodyClass">
    <ng-content>
    </ng-content>
  </mat-card-content>
  <mat-card-actions *ngIf="actionTemplate" [ngClass]="actionClass">
    <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
  </mat-card-actions>
  <mat-card-footer>

  </mat-card-footer>
</mat-card>
