// angular import
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { ErrorInterceptor } from 'src/app/services/helpers/error.interceptor';

// project import

import {AuthGuard} from "./services/guards/auth.guard";
import {NotAuthGuard} from "./services/guards/notauth.guard";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {SocketMainService} from "./services/services/socket/socket-main-service";
import {SocketIoConfig, SocketIoModule} from "ngx-socket-io";
import {environment} from "../environments/environment";
import {MessengerComponent} from "./pages/shared/messenger/messenger.component";
import {SharedModule} from "./shared/shared.module";
import {EmptyModule} from "./layouts/empty";
import {AdminModule} from "./layouts/admin/admin.module";
import {MessengerModule} from "./pages/shared/messenger/messenger.module";
import {ScrollableWallComponent} from "./shared/components/scrollable-wall/scrollable-wall.component";
import { SearchContactComponent } from './shared/chat-components/partrials/search-contact/search-contact.component';
import { ChatHeaderComponent } from './shared/chat-components/partrials/header/chat-header/chat-header.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const config: SocketIoConfig = { url: environment.socketUrl };

@NgModule({
  declarations: [AppComponent, MessengerComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    AdminModule,
    EmptyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // HttpClientModule
    SocketIoModule.forRoot(config),
    MessengerModule,
    ScrollableWallComponent,
    SearchContactComponent,
    ChatHeaderComponent,
    ChatHeaderComponent
  ],
  providers: [
    NotAuthGuard,
    AuthGuard,
    SocketMainService,
  ]
})
export class AppModule {}
