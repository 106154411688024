// angular import
import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [RouterLink],
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {}
