<div class="header-right_block">
  <div  class="header-item">
    <button class="button" [ngClass]="'active'" (click)="redirectTo('login')">Вход</button>
  </div>
  <div  aria-label="mode" class="header-item">
    <button class="button" (click)="redirectTo('register')">Регистрция</button>
  </div>


</div>
