<ng-scrollbar style="height: calc(100vh - 88px)" visibility="hover" class="mobole" >
  <app-card [showHeader]="false" cardClass="user-card" padding="20" *ngIf="showContent" >
    <div class="flex align-items-center">
      <div class="flex-shrink-0">
        <img [src]="user?.profilePic ? (env.filePath + user?.profilePic): 'assets/images/user/avatar-1.png'" alt="user-image" class="user-avatar" />
      </div>
      <div class="flex-grow-1 user-details">
        <div class="f-w-600">{{user?.firstName}} {{user?.lastName}}</div>
        <div class="mat-caption">{{user?.role}}</div>
<!--        <a class="mat-caption" *ngIf="clientType==='buyer'" (click)="openBalanceDetails()">Вывод</a>-->
<!--        <a class="mat-caption" *ngIf="clientType==='seller'" (click)="openTopup()">Пополнить</a>-->
      </div>
    </div>
  </app-card>
  <div class="navbar-content" *ngIf="user">
    <ul class="nav coded-inner-navbar" (click)="fireOutClick()">
      <ng-container *ngFor="let item of menus">
        <ng-container *ngIf="(!item.roles || item.roles.indexOf(userRole) !== -1 || item.roles.indexOf(clientType)!==-1)">
          <app-menu-group-vertical *ngIf="item.type === 'group'" [userRole]="userRole" [clientType]="clientType" [item]="item"></app-menu-group-vertical>
          <app-menu-collapse *ngIf="item.type === 'collapse' " [userRole]="userRole" [clientType]="clientType" [item]="item"></app-menu-collapse>
          <app-menu-item *ngIf="item.type === 'item'" [userRole]="userRole" [clientType]="clientType" [item]="item"></app-menu-item>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-scrollbar>
