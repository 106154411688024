// Angular import
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HeaderComponent } from './menu/header/header.component';
import { MobileMenuComponent } from './menu/mobile-menu/mobile-menu.component';
import { FooterComponent } from './footer/footer.component';
import { HeroComponent } from '../../pages/shared/hero/hero.component';
import { ContactUsComponent } from '../../pages/shared/contact-us/contact-us.component';
import { LandingBuyerComponent } from '../../pages/client/landing-buyer/landing-buyer.component';
import { BuyerAnnouncementComponent } from '../../pages/buyer/announcements/buyer-announcement.component';
import { SideBarCategoriesComponent } from '../../shared/components/side-bar-categories/side-bar-categories.component';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { DashboardSellerComponent } from '../../pages/client/dashboard-buyer/dashboard-seller.component';
import { EventManagerService } from '../../services/helpers/event-manager';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  imports: [
    HeaderComponent,
    MobileMenuComponent,
    FooterComponent,
    HeroComponent,
    ContactUsComponent,
    LandingBuyerComponent,
    BuyerAnnouncementComponent,
    SideBarCategoriesComponent,
    RouterOutlet,
    DashboardSellerComponent,
    NgIf
  ],
  standalone: true
})
export class ClientComponent implements OnInit {
  isVisible = true;
  constructor(
    private eventManagerService: EventManagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private _router: Router
  ) {
    this.eventManagerService.updateLayoutEmitter.subscribe((role: any) => {
      if (role === 'buyer') {
        this._router.navigate(['/buyer-landing-buyer/announcements']);
      }
      if (role === 'seller') {
        this._router.navigate(['/seller-landing-buyer']);
      }
      this.isVisible = false;
      this.changeDetectorRef.detectChanges();
      this.isVisible = true;
    });
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0); // Scroll to top on route change
      }
    });
  }
  ngOnInit(): void {}
}
