// Angular import
import { Component, Input, OnInit } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';

// project import
import { NavigationItem } from 'src/app/services/types/navigation';
import { ThemeLayoutService } from 'src/app/services/services/theme-layout.service';
import { HORIZONTAL, VERTICAL, COMPACT } from 'src/app/services/const';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {MeService} from "../../../../services/services/user/me.service";
import {UserService} from "../../../../services/services/user/user.service";
import {WithdrawService} from "../../../../services/services/transaction/withdraw.service";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {StorageService} from "../../../../services/helpers/storage.service";
import {UserWithdrawComponent} from "../../../../pages/admin/user/user-list/user-withdraw/user-withdraw.component";
import {UserTopupComponent} from "../../../../pages/admin/user/user-list/user-topup/user-topup.component";
import {MatDrawerMode} from "@angular/material/sidenav";

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss']
})
export class VerticalMenuComponent implements OnInit {
  // public props
  @Input() menus: NavigationItem[];
  showUser: false;
  showContent = true;
  status: string = 'true';
  modeValue: MatDrawerMode = 'side';


  user:any;
  env = environment;

  // Constructor
  public userRole: any;
  public clientType: any;
  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private meService: MeService,

    private userService: UserService,
    private withdrawService: WithdrawService,
    private eventManager: EventManagerService,
    public dialog: MatDialog,
    private popup: PopupHelper,
    protected _route: ActivatedRoute,
    protected _router: Router,
    private layout: ThemeLayoutService
  ) {


  }

  openBalanceDetails(): void {
    const dialogRef = this.dialog.open(UserWithdrawComponent, {
      data: {
        user:this.user
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.provider && (res.accountNumber && res.balance !== 0)) {
        this.withdrawService.newWithdraw({
          "amount": res.balance,
          "provider": res.provider,
          "accountNumber": res.accountNumber
        }).subscribe( e => {
          if (!e.hasError) {
            // // this.popup.printSuccess()
          }
        })
      }
    })
  }  // public method

  openTopup(): void {
    const dialogRef = this.dialog.open(UserTopupComponent, {
      data: {
        user:this.user
      },
      width: '300px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.userId && (res.balance || res.balance === 0)) {
        this.userService.updateBlance({balance:res.balance}, res.userId).subscribe( e => {
          if (!e.hasError) {
            // // this.popup.printSuccess()
          }
        })
      }
    })
  }

  fireOutClick() {
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('coded-hasmenu')) {
        parent.classList.add('coded-trigger');
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('coded-hasmenu')) {
        up_parent.classList.add('coded-trigger');
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('coded-hasmenu')) {
        last_parent.classList.add('coded-trigger');
        last_parent.classList.add('active');
      }
    }
  }

  ngOnInit(): void {
   this.userRole = StorageService.getJsonItem(StorageService.statics.currentUser)?.role;
    this.eventManager.userDataUpdate.subscribe((data) =>{
      this.user = StorageService.getJsonItem(StorageService.statics.profileData)
      this.userRole = StorageService.getJsonItem(StorageService.statics.currentUser)?.role;
      if(this.userRole !== 'admin'){
        setTimeout(()=>{
          this._router.navigate(['/']);
          if(this.user.role !== 'admin'){
            this.clientType  = this.user.customerRole;
          }
        })
      }
    })
    this.layout.layout.subscribe((res) => {
      if (res == VERTICAL) {
        this.showContent = true;
      }
      if (res == HORIZONTAL) {
        this.showContent = false;
      }
      if (res == COMPACT) {
        this.showContent = false;
      }
    });
  }
}
