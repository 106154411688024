import {Component, Input} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-announcement-item',
  templateUrl: './announcement-item.component.html',
  styleUrls: ['./announcement-item.component.scss']
})
export class AnnouncementItemComponent {
  @Input() item: any;
  data: any;


  protected readonly environment = environment;
}
