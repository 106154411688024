import {Inject, Injectable} from '@angular/core';
import {StorageService} from "./storage.service";

@Injectable()
export class Sortable {
  data: any = [];
  loading: boolean = false;
  public paginationData = {
    page: 1,
    limit: 18,
    sortBy: 'createdAt',
    sortType: 1,
    totalPages: 0,
    totalItems: 0,
    nextPage:0
  };
  public mainService;
  public client: any;

  public paginationService: any;
  public filterObject:any = null;
  public filterDefault = null;
  public pageFilter:any = null

  constructor(@Inject(Object) service: any,
              @Inject(Object) public route: any,
              @Inject(Object) public router: any,
              @Inject(Object) private servicePopupHelper: any,
              // @Inject(Object) tableDefaultFilter
  ) {

    // @ts-ignore
    this.filterDefault = {};
    this.filterObject = {};
    this.pageFilter = {}
    this.client = StorageService.getJsonItem(StorageService.statics.currentUser);
    this.route.queryParams
      .subscribe((params: { [x: string]: any; }) => {
          for (const key in params) {
            let param = null;
            // @ts-ignore
            if (this.paginationData[key] !== undefined) {
              // @ts-ignore
              this.paginationData[key] = params[key];
            } else { // @ts-ignore
              if (this.filterObject[key] !== undefined) {
                if (key === 'stateID' || key === 'raceTypeID' || key === 'id') {
                  param = Number('' + params[key]);
                }

                // @ts-ignore
                this.filterObject[key] = param || params[key];
              }
            }
          }
        }
      );
    this.mainService = service;
  }

  fetch(cb?: any) {
    // this.setParamsToUrl();
    this.mainService.getItems(this.paginationData, {...this.filterObject, ...{filter: {...this.pageFilter}}}).subscribe((item: any) => {
      if (!item.hasError) {
        this.paginationData.totalPages = item.data.meta?.totalPages;
        this.paginationData.totalItems = item.data.meta?.totalItems;
        this.paginationData.limit = item.data.meta?.itemsPerPage;
        if (item.data.meta?.totalPages > item.data.meta?.currentPage){
          this.paginationData.nextPage = item.data.meta?.currentPage + 1
        }else {
          this.paginationData.nextPage = 0
        }
        let newData = item.data.data || item.data;
        if (cb) {
          cb(newData);
        }else{
          this.data = newData
        }
      } else {
        this.servicePopupHelper.printError('Something went wrong');
      }
    }, (error: any) => {
      this.servicePopupHelper.printError('Something went wrong');
    });

  }


  setParamsToUrl() {
    const asdasfasfas = buildQueryString(this.pageFilter)

    const queryParams = {
      ...this.paginationData,
      ...this.filterObject,
      ...{filter:asdasfasfas}
    };
    // Remove null or undefined values
    Object.keys(queryParams).forEach((k) => {
      if (queryParams[k] == null || k === 'pages') {
        delete queryParams[k];
      }
    });
    this.router.navigate([], {
      queryParams,
    });
  }

  filter() {
    this.loading = true
    this.fetch((data: any[]) => {
      this.data = data;
      this.loading = false;
    });
  }
  onNext() {
    if (this.paginationData.nextPage ){
      this.paginationData.page = this.paginationData.nextPage
      this.loading = true
      debugger
      this.fetch((data: any[]) => {
        this.data = this.data.concat(data)
        this.loading = false;
      });
    }

  }


  pageChange(page: any) {
    // @ts-ignore
    this.filterObject.page = page;
    this.filter();
  }

  onSortClick(sort: any) {
    this.fetch((data: any[]) => {
      // this.data = new MatTableDataSource(data);
    });
  }



  onPrev() {
    // @ts-ignore
    this.paginationData.next = null;
    this.filter();
  }

  onCountChange() {
    // @ts-ignore
    this.paginationData.next = null;
    this.filter();
  }

  filterReset() {
    // @ts-ignore
    this.filterObject = {...this.filterDefault};
    this.filter();
  }

  runSearch(event: { keyCode: number; }) {
    if (event.keyCode === 13) {
      this.filter();
    }
  }
}
function buildQueryString(data: any, parentKey = ''): any {
  return Object.keys(data).map(key => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (Array.isArray(data[key])) {
      // Handle array values
      return data[key].map((item: any) => `${fullKey}=${item}&`).join('');
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      // Recursively handle nested objects
      return buildQueryString(data[key], fullKey);
    } else {
      const obj = {}
      // @ts-ignore
      obj[fullKey] = data[key]
      // Handle non-object and non-array values
      // @ts-ignore
      return (data[key] !== null && data[key] !== undefined) ? obj[key]  : '';
    }
  }).join('');
}
