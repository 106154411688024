// angular import
import { Component } from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {StorageService} from "../../services/helpers/storage.service";

@Component({
  selector: 'app-check-layout',
  templateUrl: './check-layout.component.html',
  standalone: true,
  imports: [RouterLink],
  styleUrls: ['./check-layout.component.scss']
})
export class CheckLayoutComponent {
  constructor(private router: Router) {
    const currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
    if(currentUser && currentUser.role === 'admin' ) {
      this.router.navigate(['/admin/products'])
    } else if(currentUser && currentUser.customerRole === 'seller' ) {
      this.router.navigate(['/seller-landing-buyer'])
    } else if(currentUser && currentUser.customerRole === 'buyer' ) {
      this.router.navigate(['/buyer-landing-buyer'])
    } else if(!currentUser) {
      this.router.navigate(['/landing-buyer'])
    }
  }
}
