// Angular import
import { Component, Input, OnInit } from '@angular/core';
import {Location, LocationStrategy, NgIf} from '@angular/common';

// project import
import { NavigationItem } from 'src/app/services/types/navigation';
import { ThemeLayoutService } from 'src/app/services/services/theme-layout.service';
import { HORIZONTAL, VERTICAL, COMPACT } from 'src/app/services/const';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router, RouterModule} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {MeService} from "../../../../services/services/user/me.service";
import {UserService} from "../../../../services/services/user/user.service";
import {WithdrawService} from "../../../../services/services/transaction/withdraw.service";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {StorageService} from "../../../../services/helpers/storage.service";
import {UserWithdrawComponent} from "../../../../pages/admin/user/user-list/user-withdraw/user-withdraw.component";
import {UserTopupComponent} from "../../../../pages/admin/user/user-list/user-topup/user-topup.component";

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  standalone: true,
  imports: [RouterModule, NgIf],
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {
  @Input() user: any;
  constructor(
    private eventManagerService:EventManagerService
  ) {

  }

  openFilter() {
    this.eventManagerService.openFilterCategory()
  }
}
