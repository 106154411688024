<ng-container *ngIf="!item.hidden">
  <li data-username="able-pro dashboard" class="nav-item coded-hasmenu" [routerLinkActive]="['active']">
    <a [routerLinkActive]="['active']" class="nav-link" (click)="navCollapse($event)">
      <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
    <ul class="coded-submenu" [routerLinkActive]="['active']" [ngClass]="item.classes!">
      <ng-container *ngFor="let item of item.children">
        <ng-container *ngIf="!item.roles || item.roles.indexOf(userRole) !== -1 || item.roles.indexOf(clientType)!==-1">
          <app-menu-group-vertical *ngIf="item.type === 'group'" [userRole]="userRole" [clientType]="clientType" [item]="item"></app-menu-group-vertical>
          <app-menu-collapse *ngIf="item.type === 'collapse'"  [userRole]="userRole" [clientType]="clientType"  [item]="item"></app-menu-collapse>
          <app-menu-item *ngIf="item.type === 'item'" [item]="item" [userRole]="userRole" [clientType]="clientType" ></app-menu-item>
        </ng-container>
      </ng-container>
    </ul>
  </li>
  <ng-template #itemContent>
    <span class="coded-micon" *ngIf="item.icon">
      <svg class="pc-icon" *ngIf="!item.iconTag">
        <use attr.xlink:href="assets/fonts/custom-icon.svg{{ item.icon }}"></use>
      </svg>
      <a href="javascript:void(0)" *ngIf="item.iconTag">
        <i class="{{item.icon}}"></i>
      </a>
    </span>

    <span class="coded-mtext">{{ item.title | translate }} </span>
  </ng-template>
</ng-container>
