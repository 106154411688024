import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../../helpers/network.helper';

@Injectable()
export class MeService {


  private updateCustomerRoleUrl:string = 'auth/role';
  private getMerUrl:string='me/get-profile';
  private updateUrl: string = 'me/update-profile'
  private updateUserPasswordUrl: string = 'me/update_password'


  private addPassportUrl: string = 'passport'
  private getDashboardInfo: string = 'me/dashboard'


  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {
  }


  updateCustomerRole(data: any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateCustomerRoleUrl, data);
  }

  me(): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getMerUrl);
  }

  updateUser(data:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateUrl, data);
  }

  updateUserPassword(data: any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateUserPasswordUrl, data);
  }


  addPassport(data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.addPassportUrl, data);
  }

  getDashboard(){
    return this.networkHelper.get(environment.apiURL + this.getDashboardInfo);
  }

}
