<ng-scrollbar style="height: calc(100vh - 88px)" visibility="hover">
  <div class="navbar-content">
    <ul class="nav coded-inner-navbar" (click)="fireOutClick()">
      <ng-container *ngFor="let item of menus">
        <app-menu-group-compact *ngIf="item.type === 'group'" [item]="item"></app-menu-group-compact>
        <app-menu-collapse-compact *ngIf="item.type === 'collapse'" [item]="item"></app-menu-collapse-compact>
        <app-menu-item-compact *ngIf="item.type === 'item'" [item]="item"></app-menu-item-compact>
      </ng-container>
    </ul>
  </div>
</ng-scrollbar>
