<app-card [showHeader]="false" [padding]="16" class="text-center">
  <div class="text-center">
    <app-star-rating [rating]="starCount" [starCount]="5" (ratingUpdated)="onRatingChanged($event)"></app-star-rating>
    <mat-form-field appearance="outline" class="w-100 comment-section border-1">
    <textarea matInput [(ngModel)]="message" placeholder="Напишите мнение" name="inputField">

    </textarea>
    </mat-form-field>
    <div class="flex align-item-center justify-content-between m-t-10 text-center">
      <div>
        <button mat-flat-button color="primary" (click)="save()">Оценить</button>
      </div>
    </div>
  </div>

</app-card>
