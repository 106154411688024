import {Component, Input, OnInit} from '@angular/core';
import {ReviewsService} from "../../../../services/services/review/reviews.service";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-new-review',
  templateUrl: './new-review.component.html',
  styleUrls: ['./new-review.component.scss']
})
export class NewReviewComponent implements OnInit{
  @Input() type:any
  @Input() id:any

  message: any = '';

  starCount = 5;
  protected readonly environment = environment;

  constructor(private reviewService: ReviewsService, protected  popup: PopupHelper) {
  }
  ngOnInit(): void {
  }

  onRatingChanged($event: any){
    this.starCount = $event;
  }

  save(){
    this.reviewService.create({
      [this.type]: this.id,
      "title": this.message,
      "rate": this.starCount
    }).subscribe((data:any) => {
      if(!data.hasError){
        // this.popup.printSuccess('Вы успешно оценили продавца!');
      }else{
        this.popup.printError(data.errorMessage)
      }
    })
  }
}
