// Angular import
import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';

import {RouterModule} from "@angular/router";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {TranslateModule} from "@ngx-translate/core";
import {SocketMainService} from "../../../../services/services/socket/socket-main-service";
import {MeService} from "../../../../services/services/user/me.service";
import {StorageService} from "../../../../services/helpers/storage.service";

@Component({
  selector: 'app-mobile-new-menu',
  templateUrl: './mobile-menu.component.html',
  standalone: true,
  imports: [RouterModule, NgIf, TranslateModule],
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit{
  @Input() user: any;
  notSeenCount = 0;
  notSeenMessagesCount = 0;
  constructor(
    private eventManagerService:EventManagerService,
    private socketService: SocketMainService,
    private meService: MeService,
  ) {
    this.user = StorageService.getJsonItem(StorageService.statics.currentUser);
    this.notSeenMessagesCount = this.user?.notSeenNotificationCount;
    this.notSeenCount = this.user?.notSeenRooms;
    this.socketService.listenToEventWithCallback('ADD','notification', (event: any)=>{
      this.notSeenCount++;
    })
    this.socketService.listenToEventWithCallback('ADD','chat', (event: any)=>{
      this.notSeenMessagesCount++;
    })
  }

  ngOnInit(): void {
    if(StorageService.getToken()){
      this.meService.me().subscribe((data: any) => {
        this.user = data.data.user;
        this.eventManagerService.userDataUpdate.emit(this.user);
        this.eventManagerService.notificationsCountEmmiter.emit(data.data.notSeenNotificationCount);
        this.eventManagerService.messagesCountEmmiter.emit(data.data.notSeenRooms);
      });
      this.eventManagerService.messagesCountEmmiter.subscribe((data: any)=>{
        this.notSeenMessagesCount = data;
      })

      this.eventManagerService.notificationsCountEmmiter.subscribe((data: any)=>{
        this.notSeenCount = data;
      })
    }
  }

  openFilter() {
    this.eventManagerService.openFilterCategory()
  }
}
