<div class="product-page-header">
  <div class="filter-select" style="display:flex;">
    <button [matMenuTriggerFor]="genderMenu" mat-button>{{'gender'| translate}} ↓</button>
    <mat-menu #genderMenu="matMenu" yPosition="above">
      <mat-radio-button (change)="input($event, FilterOperator.AND.value, ['product','gender'])"
                    *ngFor="let gender of genderSelectBox"
                    [value]="gender.value"
                    class="filter">{{ gender.label }}
      </mat-radio-button>
    </mat-menu>

    <button [matMenuTriggerFor]="ratingMenu" mat-button>{{'rating' | translate}} ↓</button>
    <mat-menu #ratingMenu="matMenu" yPosition="below">
      <mat-radio-button (change)="input($event,FilterOperator.BTW.value, ['account', 'sellerRating'])"
                    *ngFor="let gender of reatingSelectBox"
                    [value]="gender.value"
                    class="filter">{{ gender.label }}
      </mat-radio-button>
    </mat-menu>
  </div>
</div>
