import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../../helpers/network.helper';

@Injectable()
export class ChatService {
  private openRoomUrl= 'conversation/open-room';
  private updateUrl='conversation/:id';
  private newUrl="messages/:conversationId/new";
  private getUrl='conversation'
  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {}

  getItems(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUrl, {...pagination,...filter});
  }
  getById(id:string,type: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.updateUrl.replace(':id',id),type);
  }
  new(id:string, data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.newUrl.replace(':conversationId',id),data);
  }
  openRoom( data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.openRoomUrl,data);
  }

}
