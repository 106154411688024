<ng-container *ngIf="!item.hidden">
  <li class="nav-item coded-menu-caption">
    <label>{{ item.title | translate }}</label>
  </li>
  <ng-container *ngFor="let item of item.children">
    <app-menu-group-compact *ngIf="item.type === 'group'" [item]="item"></app-menu-group-compact>
    <app-menu-collapse-compact *ngIf="item.type === 'collapse'" [item]="item"></app-menu-collapse-compact>
    <app-menu-item-compact *ngIf="item.type === 'item'" [item]="item"></app-menu-item-compact>
  </ng-container>
</ng-container>
