export class StorageService {
    static statics = {
        'currentUser': 'currentUser',
        'token': 'token',
        'role': 'role',
        'profileData': 'profileData',
        'initialInfo': 'initialInfo',
        'landingRole': 'landingRole',
        'add-address': 'add-address',
        'reginfo': 'registerinfo',
        'cache': {
            'regions': 'regions',
            'translationDefaultModel': 'translationDefaultModel'
        },
        'tables': {
            'subscriptions':'subscriptions',
            'transactions':'transactions',
            'settings':'settings',
            'localizations': 'localizations',
            'users':'users',
            'games':'games',
            'gameTypes':'gameTypes',
            'gameTypeConfigs':'gameTypeConfigs',
            'gameConditions':'gameConditions',
            'gameSessions':'gameSessions',
            'trackUrls':'trackUrls',
            'trackSessions':'trackSessions',
            'challengeTemplate':'challengeTemplate',
            'challenges':'challenges',
            'challengeGroup':'challengeGroup',
            'eventTemplates':'eventTemplates',
            'events':'events',
            'tournamentTemplates':'tournamentTemplates',
            'tournaments':'tournaments',
            'stageTemplates':'stageTemplates',
            'stages':'stages',
            'betOnMeParticipant':'betOnMeParticipant',
            'betOnMe':'betOnMe',
            'gallery':'gallery',
            'slider':'slider',
            'banner':'banner',
            'friends':'friends',
            'friend-invitations':'friend-invitations',
            'followers': 'followers',
            'posts':'posts',
            'messages':'messages',
            'comments':'comments',
            'publishers':'publishers',
            'rooms':'rooms',
            'sponsors':'sponsors',
            'teams':'teams',
            'formData':'form-data',
            'formBuilder':'form-builder',
            'disputeTable':'disputeTable',
            'pushNotifications':'push-notifications',
            'pages':'pages',
            'withdraw':'withdraw',
            'package':'package'
        }
    };

    constructor() {
    }

    static getItem(name: any):string {
        return localStorage.getItem(name)|| '';
    }

    static getJsonItem(name: any) {
      let item = StorageService.getItem(name);
      try {
        return (name)?JSON.parse(item):null;
      } catch (e) {
        return null;
      }
    }

    static clear() {
        return localStorage.clear();

    }

    static setItem(name: string, value: string) {
        localStorage.setItem(name, value);
    }

    static setJsonItem(name: string, obj: any) {
        StorageService.setItem(name, JSON.stringify(obj));
    }

    static getToken():string {
        return StorageService.getItem(StorageService.statics.token);
    }

    static getUserName() {
        return (StorageService.getJsonItem(StorageService.statics.currentUser)) ?
            StorageService.getJsonItem(StorageService.statics.currentUser).firstName : null;
    }

    static removeItem(name: string) {
        localStorage.removeItem(name);
    }

    static getUserId() {
        return (StorageService.getJsonItem(StorageService.statics.currentUser) &&
            StorageService.getJsonItem(StorageService.statics.currentUser).userId) ?
            StorageService.getJsonItem(StorageService.statics.currentUser).userId : null;
    }

    static getUser() {
        return (StorageService.getJsonItem(StorageService.statics.currentUser)) ?
            StorageService.getJsonItem(StorageService.statics.currentUser) : null;
    }
}
