import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../../helpers/network.helper';

@Injectable()
export class UserService {

  private loginUrl = 'user';
  private getByIdUrl = 'user/:id';
  private getByIdAndRoleUrl = 'user/:id/:role';
  private deletedUrl: string = 'user/:id';
  private updateBalanceUrl:string='user/balance/:id';
  private usersListUrl:string = 'user/by-role'

  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {
  }

  getItems(pagination: any, filter: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.loginUrl, {...pagination, ...filter});
  }
  getById(id: string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getByIdUrl.replace(':id', id));
  }
  update(data: any, id: string): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.getByIdUrl.replace(':id', id), data);
  }
  updateBlance(data: any, id: string): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateBalanceUrl.replace(':id', id), data);
  }
  updateCustomerRole(data: any, id: string): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateBalanceUrl, data);
  }
  create(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.loginUrl, data);
  }
  deleted(id: string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deletedUrl.replace(':id', id));
  }

  getByIdAndRole(id: string, role: string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getByIdAndRoleUrl.replace(':id', id).replace(':role', role));
  }

  getUsersList(pagination: any, filter: any, role: string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.usersListUrl+'/' + role, {...pagination, ...filter});
  }
}
