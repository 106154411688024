import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../../helpers/network.helper';

@Injectable()
export class OrderService {
  private getOrdersList="order";
  private createOrderUrl="order/:id";
  private myOrdersUrl="me/orders";
  //TODO: make get requests to put
  private acceptUrl="order/accept/:id";
  private rejectUrl="order/reject/:id";
  private completeUrl="order/complete/:id";

  private disagreeUrl = "order/disagreement/:id";
  private processTaskUrl ="order/:id";




  private decisionUrl="order/decision/:id";
  private disputesUrl="order/dispute";


  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) { }

  getItems(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getOrdersList, {...pagination,...filter});
  }
  getById(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.createOrderUrl.replace(':id',id));
  }
  createOrder(id: any, data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.createOrderUrl.replace(':id',id), data);
  }
  acceptOrder(id: any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.acceptUrl.replace(':id',id),{});
  }
  rejectOrder(data:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.rejectUrl.replace(':id', data), {} );
  }

  submitTask(id: any, imagePath: any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.processTaskUrl.replace(':id', id), {"screenshot": imagePath});
  }

  taskWasNotDone(id: any, message:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.disagreeUrl.replace(':id', id), {message} );
  }
  taskWasDone(id: any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.completeUrl.replace(':id', id) );
  }
  acceptTaskWasNotDone(id: any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.disagreeUrl.replace(':id', id), {"disagreement": true} );
  }
  rejectTaskWasNotDone(id: any, message:any): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.disagreeUrl.replace(':id', id), {message, "disagreement": false} );
  }

  acceptDispute(id: any, message:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.decisionUrl.replace(':id', id), {message, "sellerCorrect": false} );
  }
  rejectDispute(id: any, message:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.decisionUrl.replace(':id', id), {message, "sellerCorrect": true} );
  }

  disputeList(data:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.disputesUrl, data );
  }

  myOrders(data:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.myOrdersUrl, data );
  }
}
