// Angular Import
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {CommonModule, NgForOf} from '@angular/common';

// Project Import
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-star-rating',
  standalone: true,
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  imports: [
    MatTooltipModule,
    MatIconModule,
    NgForOf
  ]
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number = 2;
  @Input() starCount: number = 5;
  @Output() ratingUpdated = new EventEmitter();

  snackBarDuration: number = 2000;
  // eslint-disable-next-line
  ratingArr: any = [];

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    for (let i = 0; i < this.starCount; i++) {
      this.ratingArr.push(i);
    }
  }
  onClick(rating: number) {
    this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
      duration: this.snackBarDuration
    });
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(i: number) {
    if (this.rating >= i + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}
