<!--<div class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">-->
<!--  <div class="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">-->
<!--    <button [routerLink]="[user?.customerRole === 'seller'? 'seller-landing-buyer/products':'buyer-landing-buyer/announcements']" type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">-->
<!--      <i class="fa fa-home" style="color: royalblue">-->
<!--      </i>-->
<!--    </button>-->
<!--    <button  [routerLink]="[user?.customerRole === 'seller'? 'seller-landing-buyer/buyers':'buyer-landing-buyer/sellers']" type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">-->
<!--      <i class="fa fa-tasks" style="color: royalblue"></i>-->
<!--    </button>-->
<!--    <button  (click)="openFilter()" type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">-->

<!--      <i class="fa fa-tasks" style="color: royalblue"></i>-->
<!--    </button>-->
<!--    <button [routerLink]="['transaction']" type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">-->
<!--      <i class="fa fa-wallet" style="color: royalblue">-->
<!--      </i>-->
<!--    </button>-->
<!--    <button [routerLink]="['my-profile/personal']" type="button" class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">-->
<!--      <i class="fa fa-wallet" style="color: royalblue">-->
<!--      </i>-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->






<div class="w-full lg:hidden xl:hidden md:hidden">
  <!-- <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile-->
  <section id="bottom-navigation" class="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
    <div id="tabs" class="flex justify-between">

      <a *ngIf="user?.customerRole === 'buyer'" [routerLink]="['/buyer-landing-buyer']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
        <i class="fa fa-home"></i>
        <span class="tab tab-home block text-xs">{{'main'|translate}}</span>
      </a>
      <a *ngIf="user?.customerRole === 'seller'" [routerLink]="['/seller-landing-buyer']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
        <i class="fa fa-home"></i>
        <span class="tab tab-home block text-xs">{{'main'|translate}}</span>
      </a>
      <a  *ngIf="!user" [routerLink]="['/seller-landing-buyer']" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
        <i class="fa fa-home"></i>
        <span class="tab tab-home block text-xs">{{'main'|translate}}</span>
      </a>
      <a  (click)="openFilter()" class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
        <i class="fa-list"></i>
        <span class="tab tab-kategori block text-xs">{{'catalog' | translate}}</span>
      </a>
      <a [routerLink]="['/notifications']" class="w-full relative focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
        <i class="fa fa-bell"></i>
        <span class="tab tab-explore block text-xs">{{'notifications' | translate}}</span>
        <span *ngIf="notSeenCount" class="notification-badge" style="right: 30%">{{notSeenCount}}</span>
      </a>
      <a  [routerLink]="['/messenger']" class="w-full relative focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1">
        <i class="fa fa-comment"></i>
        <span class="tab tab-whishlist block text-xs">{{'comments' | translate}}</span>
        <span *ngIf="notSeenMessagesCount" class="notification-badge" style="right: 30%">{{ notSeenMessagesCount }}</span>
      </a>
      <a  class="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
          [routerLink]="'/account-menu'"
      >
        <i class="fa fa-user"></i>
        <span class="tab tab-account block text-xs">{{'account' | translate}}</span>
      </a>
    </div>
  </section>
</div>
