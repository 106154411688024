<div class="header-right_block">
  <div (click)="redirectToChat()" class="header-item text-white">
    <a class="pc-head-link badge-on-button text-white" href="javascript:" matBadgeOverlap="false">
      <svg class="pc-icon">
        <use xlink:href="assets/fonts/custom-icon.svg#custom-message-2"></use>
      </svg>
      <span *ngIf="notSeenMessagesCount" class="notification-badge">{{ notSeenMessagesCount }}</span>
    </a>
  </div>
  <app-notifications-widget></app-notifications-widget>

  <div [matMenuTriggerFor]="user" aria-label="mode" class="header-item">
    <a class="pc-head-link text-white">
      <img [src]="profileObj?.profilePic ? (env.filePath + profileObj?.profilePic) : 'assets/images/user/avatar-2.png'"
           alt="user-image" class="user-avatar"/>
    </a>
  </div>

  <mat-menu #user="matMenu" class="dropdown-user-profile" xPosition="before">
      <div class="dropdown-body">
        <div class="flex m-b-5">
          <div class="topic-icon">
            <img
              [src]="profileObj?.profilePic ? (env.filePath + profileObj?.profilePic)  : 'assets/images/user/avatar-2.png'"
              alt="user-images" class="user-avatar"/>
          </div>
          <div class="topic-description m-l-15">
            <div class="user-name mat-body f-w-600">{{ profileObj?.nickName || 'anonymous' }}</div>
            <p class="mat-caption">{{ profileObj?.balance | roundCurrency: profileObj?.currency }}</p>
          </div>
        </div>
        <div *ngIf="userObj && userObj.role !== 'admin'" class="grid m-b-15">
          <app-customer-role [fromMenu]="true"></app-customer-role>
        </div>
        <mat-divider class="m-y-15"></mat-divider>
        <div class="grid m-b-15">
          <a [routerLink]="['/my-profile']" class="link">
            {{'my_profile'| translate}}
          </a>
        </div>

        <div *ngIf="userObj?.role === 'admin' && userObj?.customerRole !== 'admin'" class="grid m-b-15">
          <a [routerLink]="['/my-profile']" class="link">
            {{'admin_dashboard'| translate}}
          </a>
        </div>
        <div *ngIf="userObj?.role === 'admin' && userObj?.customerRole !== 'admin'" class="grid m-b-15">
          <a [routerLink]="['/admin', '/products']" class="link">
            {{'admin_dashboard'| translate}}
          </a>
        </div>

        <div *ngIf="userObj?.customerRole === 'buyer'" class="grid m-b-15">
          <a (click)="openBalanceDetails()" class="link">
            {{'withdraw'| translate}}
          </a>
        </div>
        <div *ngIf="userObj?.customerRole === 'seller'" class="grid m-b-15">
          <a (click)="openTopup()" class="link">
            {{'topup_task'| translate}}
          </a>
        </div>
        <div *ngIf="userObj?.customerRole === 'seller' || userObj?.customerRole === 'buyer'" class="grid m-b-15">
          <a [routerLink]="['/order/list']" class="link">
            {{'active_tasks' | translate}}
          </a>
        </div>

        <div class="grid m-b-15">
          <a [routerLink]="['/transaction']" class="link">
            {{'transaction_history'| translate}}
          </a>
        </div>
        <mat-divider class="m-b-15 m-t-15"></mat-divider>
        <div class="grid m-b-15">
          <a (click)="logout()" class="link">
            {{'logout'| translate}}
            <svg class="pc-icon m-l-10 inline-block">
              <use xlink:href="assets/fonts/custom-icon.svg#custom-logout-1-outline"></use>
            </svg>
          </a>
        </div>
      </div>
  </mat-menu>

</div>
