// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';

// Project import
import { AbleProConfig } from 'src/app/app-config';
import { ThemeLayoutService } from 'src/app/services/services/theme-layout.service';
import { MIN_WIDTH_1025PX, MAX_WIDTH_1024PX, VERTICAL, HORIZONTAL, COMPACT, RTL, LTR } from 'src/app/services/const';

import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {CustomerRoleComponent} from "../../shared/components/customer-role/customer-role.component";
import {MatDialog} from "@angular/material/dialog";
import {MeService} from "../../services/services/user/me.service";
import {EventManagerService} from "../../services/helpers/event-manager";
import {StorageService} from "../../services/helpers/storage.service";
import {menus} from "./data/menu";
import {AnnouncementService} from "../../services/services/announcement/announcement.service";
import {PopupHelper} from "../../services/helpers/popup.helper";
import {Sortable} from "../../services/helpers/sortable";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../shared/shared.module";
import {NarikCustomValidatorsModule} from "@narik/custom-validators";
import {
  BuyerAnnouncementDetailsComponent
} from "../../pages/buyer/announcements/buyer-announcement-details/buyer-announcement-details.component";
import {CategoryComponent} from "../../shared/components/category/category.component";
import {FilterComponent} from "../../shared/components/filter/filter.component";
import {ScrollableWallComponent} from "../../shared/components/scrollable-wall/scrollable-wall.component";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent  implements OnInit {
  // public props
  @ViewChild('sidebar') sidebar: MatDrawer;
  menus = menus;
  modeValue: MatDrawerMode = 'side';
  direction: string;
  user: any;
  // currentApplicationVersion = environment.appVersion;
  currentLayout: string;
  rtlMode: boolean;
  windowWidth: number;

  // Constructor
  constructor(
    protected productService:AnnouncementService,
    private breakpointObserver: BreakpointObserver,
    private eventManagerService: EventManagerService,
    private router: Router,
    private layoutService: ThemeLayoutService,
    public dialog: MatDialog,
    private meService: MeService,
  )

  {
    this.user = StorageService.getJsonItem(StorageService.statics.currentUser);
    if (this.user){
      this.currentLayout = this.user.role === 'admin' ? AbleProConfig.layout: 'horizontal';
    }

    this.rtlMode = AbleProConfig.isRtlLayout;
    this.windowWidth = window.innerWidth;
  }

  // life cycle event
  ngOnInit() {
    if (this.user && !this.user.customerRole){
      this.openCustomerRoleModal()
    }

    this.eventManagerService.userDataUpdate.subscribe((data: any)=>{
      this.user = {...this.user, ...data};
      this.currentLayout = this.user.role === 'admin' ? AbleProConfig.layout: 'horizontal';
      if(data.roleChange && this.user.role !== 'admin'){
        if (this.user.customerRole === 'buyer') {
          if(!this.user.verified && !data.fromMenu ){
            this.router.navigate(['/onboarding'])
          } else {
            this.router.navigate(['/buyer-home/announcements'])
          }
        } else {
          if(!this.user.verified && !data.fromMenu){
            this.router.navigate(['/onboarding/seller'])
          } else {
            this.router.navigate(['/seller-home/products'])
          }
        }
      }
    })
    this.breakpointObserver.observe([MIN_WIDTH_1025PX, MAX_WIDTH_1024PX]).subscribe((result) => {
      if (result.breakpoints[MAX_WIDTH_1024PX]) {
        this.modeValue = 'over';
      } else if (result.breakpoints[MIN_WIDTH_1025PX]) {
        this.modeValue = 'side';
      }
    });
    // if(localStorage.getItem('role') !== 'admin'){
    //   this.currentLayout = 'horizontal';
    // }

    /**
     * Dashboard menu sidebar toggle listener
     */
    this.layoutService.dashBoardMenuState.subscribe(() => {
      this.sidebar.toggle();
    });

    this.manageLayout(this.currentLayout);

    /**
     * Listen to Theme direction change. RTL/LTR
     */
    this.layoutService.directionChange.subscribe((direction: string) => {
      // here direction layout subscribing according on click event in configuration
      this.rtlMode = direction === RTL ? true : false;
      // if(localStorage.getItem('role') !== 'admin'){
      //   this.currentLayout = 'horizontal';
      // }
      this.manageLayout(this.currentLayout);
    });

    /**
     * Listen to theme layout changes
     */
    this.layoutService.layout.subscribe((layout: string) => {
      // here direction layout subscribing according on click event in configuration
      // if(localStorage.getItem('role') !== 'admin'){
      //   this.currentLayout = 'horizontal';
      // }
      this.manageLayout(layout);
    });
  }


  openCustomerRoleModal(): void {
    const dialogRef = this.dialog.open(CustomerRoleComponent, {
      width: '400px',
      panelClass: 'customer-role-dialog-container'
    });
  }

  /**
   * Manage layout of theme
   */
  private manageLayout(layout: string) {
    const drawerContent = document.querySelector('.mat-drawer-content') as HTMLElement;
    if (drawerContent) {
      if (layout === VERTICAL) {
        if (this.windowWidth > 1025) {
          drawerContent.style.marginLeft = this.rtlMode === true ? '0px' : '280px';
          drawerContent.style.marginRight = this.rtlMode === true ? '280px' : '0px';
        }
        this.direction = this.rtlMode === true ? RTL : LTR;
      } else if (layout === COMPACT) {
        if (this.windowWidth > 1025) {
          drawerContent.style.marginLeft = this.rtlMode == true ? '0px' : '90px';
          drawerContent.style.marginRight = this.rtlMode == true ? '90px' : '0px';
        }
        this.direction = this.rtlMode == true ? RTL : LTR;
      } else if (layout == HORIZONTAL) {
        drawerContent.style.marginLeft = '0px';
      }
    }
  }

  // loadMore(){
  //   if (!this.loading){
  //     this.loading = true
  //     this.onNext();
  //
  //   }
  //
  //   // if(this.next){
  //   //   this.getMyTeams()
  //   // }
  // }

}
