// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import {Router, RouterModule} from "@angular/router";
import {BreadcrumbComponent} from "../../../layouts/admin/breadcrumb/breadcrumb.component";
@Component({
  selector: 'app-admin-wrapper',
  standalone: true,
  imports: [RouterModule, BreadcrumbComponent],
  template: '<div class="container">' +
              '<app-breadcrumb [dashboard]="true"></app-breadcrumb>' +
              '<router-outlet></router-outlet>' +
            '</div>',
  styleUrls: ['admin-wrapper.component.scss']
})
export class AdminWrapperComponent {
  constructor() {
  }
}
