<div *ngIf="dashboard">
  <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
    <div class="page-header" *ngIf="last && breadcrumb.breadcrumbs !== false">
      <div class="page-block">
        <div class="row align-item-center">
          <div class="col-md-12">
            <div class="flex align-item-center">
              <ul class="breadcrumb align-item-center">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard']" *ngIf="type === 'theme2'" title="Home"><i class="ti ti-home-2"></i></a>
                  <a [routerLink]="['/dashboard']" *ngIf="type === 'theme1'">Home</a>
                </li>
                <ng-container *ngFor="let breadcrumb of navigationList">
                  <li class="breadcrumb-item" *ngIf="breadcrumb.url !== false">
                    <a [routerLink]="breadcrumb.url">{{ breadcrumb.title }}</a>
                  </li>
                  <li class="breadcrumb-item" *ngIf="breadcrumb.url === false && breadcrumb.type !== 'group'">
                    <a href="javascript:">{{breadcrumb.title}}</a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <div class="page-header-title">
              <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
                <h2 *ngIf="last" class="f-w-700">
                  {{ breadcrumb.title }}
                </h2>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
