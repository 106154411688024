  <mat-form-field appearance="outline" class="w-100 m-t-5" *ngIf="type === 'select'">
    <mat-label>{{label|translate}}</mat-label>
    <mat-select  [formControl]="formControlItem"  (ngModelChange)="modelChange($event)">
      <mat-option *ngFor="let item of selectBoxOptions" [value]="item.value">{{item.label}}</mat-option>
    </mat-select>
  </mat-form-field>



  <ng-container *ngIf="type === 'checkBox'">
        <mat-checkbox
          color="primary"
          *ngFor="let item of selectBoxOptions"
          [value]="item.value"
          (change)="modelChange($event)"
        >{{item.label}} </mat-checkbox>
  </ng-container>


