import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EventManagerService} from "../../../services/helpers/event-manager";
import {NgScrollbar} from "ngx-scrollbar";
// import {map} from "rxjs/operators";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-scrollable-wall',
  templateUrl: './scrollable-wall.component.html',
  standalone: true,
  imports: [
    NgScrollbar
  ],
  styleUrls: ['./scrollable-wall.component.scss']
})
export class ScrollableWallComponent implements OnInit, OnDestroy, AfterViewInit{
  @Output() load : EventEmitter<any>
  @Input() scrollToBottom: boolean = false
  @Input() roomId:any;
  @Input() height = 'calc(100vh - 104px)'
  @Input() scrollHeight = '90%'
  @Input() className: string = ''

  @ViewChild('scrollContent') scrollContent: any;
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;


  private _scrollSubscription = Subscription.EMPTY;
  selector: string = ".main-panel";

  constructor(private eventManager: EventManagerService) {
    // this.eventManager.scrollToTop.subscribe((data: any) => {
    //   if(data === this.roomId){
    //     e.target.scrollTop = this.scrollContent.nativeElement.offsetHeight - e.target.offsetHeight;
    //   }
    // })
    this.load = new EventEmitter<any>();
  }

  ngOnInit(): void {
    //document.body.style.overflow = 'hidden';
  }

  ngAfterViewInit() {
    this.scrollbarRef.scrolled.subscribe(e => this.onScroll(e));
  }

  ngOnDestroy() {
    this._scrollSubscription.unsubscribe();
  }
  onScroll(e:any): void {
    if ((e.target.offsetHeight + e.target.scrollTop) >= this.scrollContent.nativeElement.offsetHeight - 400 && !this.scrollToBottom && this.load) {
        this.load.emit();
    } else if(this.scrollToBottom && e.target.scrollTop <= 400){
        let fixScrollPosition = this.scrollContent.nativeElement.offsetHeight - e.target.offsetHeight - e.target.scrollTop;
        this.load.emit({fixScrollPosition, scrollViewObject: e.target, scrollContent: this.scrollContent.nativeElement});
    }
  }
}
