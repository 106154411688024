import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-header',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './chat-header.component.html'
})
export class ChatHeaderComponent implements OnInit{
  @Input() forWidget:boolean = false;
  // @Input() toggleButton: boolean = false;
  // @Output() onToggle: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  // toggle() {
  //   this.onToggle.emit();
  // }
}
