<a class="flex align-item-center">
  <div
    class="text-warning-500 m-l-5"
    *ngFor="let ratingId of ratingArr; index as i"
    [id]="'star_' + i"
    (click)="onClick(i + 1)"
    [matTooltip]="ratingId + 1"
    matTooltipPosition="above"
  >
    <mat-icon style="color: #fca95d">
      {{ showIcon(i) }}
    </mat-icon>
  </div>
</a>
