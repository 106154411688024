<app-card [showHeader]="false" *ngIf="data">
  <div class="container">
  <div class="row align-item-center">
    <div class="col-xxl-4 col-xl-5">
      <h2 class="m-b-10">
        {{ data.rate ? +data.rate.toFixed(0) : 0 }}
        <span class="text-muted f-16">/5</span>
      </h2>
      <p class="text-muted" *ngIf="data.rateQuantity">На основе {{data.rateQuantity}} оценок</p>
      <p class="text-muted" *ngIf="!data.rateQuantity">{{'No ratings yet'|translate}}</p>

      <app-star-rating [rating]="data.rate? +data.rate.toFixed(0): 0 " [starCount]="starCount" (ratingUpdated)="onRatingChanged($event)"></app-star-rating>
    </div>
    <div class="col-xxl-4 col-xl-5">
      <div class="flex align-item-center">
        <div class="w-100">
          <div class="row align-item-center m-y-10" *ngFor="let item of rating">
            <div class="col">
              <mat-progress-bar mode="buffer" value="{{ item.value? item.value : 0 }}" bufferValue="{{item.bufferValue}}"></mat-progress-bar>
            </div>
            <div class="col-auto">
              <p class="m-b-0 text-muted">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</app-card>

