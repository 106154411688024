import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CommonModule,} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {NarikCustomValidatorsModule} from "@narik/custom-validators";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MatDrawerMode} from "@angular/material/sidenav";
import {FilterOperator} from "../../../services/constants";
import {CategoryService} from "../../../services/services/category/category.service";
import {AnnouncementService} from "../../../services/services/announcement/announcement.service";
import {PopupHelper} from "../../../services/helpers/popup.helper";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, NarikCustomValidatorsModule,RouterLink],
  providers: [CategoryService,],

})

export class CategoryComponent  implements AfterViewInit, OnInit {

  @Output()sendToParrent:EventEmitter<any> = new EventEmitter<any>()

  showFiller = true;
  modeValue: MatDrawerMode = 'side';

  protected readonly environment = environment;

  // constructor
  constructor(public dialog: MatDialog,
              public categoryService: CategoryService,
              protected popup: PopupHelper,
              protected _route: ActivatedRoute,
              protected _router: Router,
  ) {

  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
  }

  select(event: any, filterOperator: any, operators: string[]){
    this.sendToParrent.emit({event, filterOperator, operators})
  }
  protected readonly FilterOperator = FilterOperator;
}
