<div class="mobile-menu">
  <ul>
    <li>
      <a [routerLink]="[user.customerRole === 'seller'? 'seller-landing-buyer/products':'buyer-landing-buyer/announcements']">
        <i class="fa fa-home" style="color: royalblue">
        </i>
      </a>
    </li>
    <li>
      <a [routerLink]="[user.customerRole === 'seller'? 'seller-landing-buyer/buyers':'buyer-landing-buyer/sellers']">
        <i class="fa fa-users" style="color: royalblue">
        </i>
      </a>

    </li>
    <li>
      <a (click)="openFilter()">
        <i class="fa fa-tasks" style="color: royalblue">
        </i>
      </a>
    </li>
    <li>
      <a  [routerLink]="['transaction']">
        <i class="fa fa-wallet" style="color: royalblue">
        </i>
      </a>
    </li>
    <li>
      <a  [routerLink]="['my-profile/personal']">
        <i class="fa fa-cogs" style="color: royalblue">
        </i>
      </a>
    </li>
  </ul>
</div>
