// angular import
import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';

// project import
import {ThemeLayoutService} from 'src/app/services/services/theme-layout.service';
import {COMPACT, HORIZONTAL, VERTICAL} from 'src/app/services/const';
import {StorageService} from "../../../../services/helpers/storage.service";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {AnnouncementService} from "../../../../services/services/announcement/announcement.service";
import {Sortable} from "../../../../services/helpers/sortable";

@Component({
  selector: 'app-nav-middle',
  templateUrl: './toolbar-middle.component.html',
  styleUrls: ['./toolbar-middle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarMiddleComponent implements OnInit {
  @Output() HeaderBlur = new EventEmitter();

  showToggleMenu: boolean = true;
  user:any;
  inProgressCall: any;
  suggestions: any =  [];

  openComplete:any = false;
  // constructor
  constructor(private layoutService: ThemeLayoutService,
              private storageService: StorageService,
              private announcementService: AnnouncementService,
              private eventManagerService: EventManagerService) {}

  ngOnInit() {
    this.user = StorageService.getJsonItem(StorageService.statics.currentUser);
    this.eventManagerService.userDataUpdate.subscribe(()=>{
      this.user = StorageService.getJsonItem(StorageService.statics.currentUser);
    })
    this.layoutService.layout.subscribe((res) => {
      if (res === VERTICAL) {
        this.showToggleMenu = true;
      }
      if (res == HORIZONTAL) {
        this.showToggleMenu = false;
      }
      if (res === COMPACT) {
        this.showToggleMenu = true;
      }
    });
  }

  // public method
  toggleMenu() {
    this.layoutService.toggleSideDrawer();
  }

  onInput(event: any): void {
    const query = event.target.value;
    if(this.inProgressCall){
      this.inProgressCall.unsubscribe();
    }
    this.inProgressCall = this.announcementService.getItems({limit:10, page:1}, {searchBy:'product.name', search:query});
    setTimeout(()=>{
      this.inProgressCall.subscribe((items:any) => {
        this.inProgressCall = null;
        this.suggestions = items?.data.data
        this.openComplete = true;

      }, ()=>{
        this.inProgressCall = null;
      });
    },300)

  }

  closeSearch(){
    this.openComplete = false;
  }

}
