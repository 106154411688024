// Angular Import
import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationEnd, Router, RouterModule, Event} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {SharedModule} from "../../../shared/shared.module";
import {NavigationItem} from "../../../services/types/navigation";
import {menus} from "../data/menu";

// import {split} from "lodash";

interface titleType {
  // eslint-disable-next-line
  url: string | boolean | any | undefined;
  title: string;
  breadcrumbs: unknown;
  type: string;
  link: string | undefined;
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent{
  // public props
  @Input() type: string;
  @Input() Component = false;
  @Input() dashboard = true;

  navigations: NavigationItem[];
  breadcrumbList: Array<string> = [];
  navigationList: titleType[];
  componentList: titleType[];

  // constructor
  constructor(
    private route: Router,
    private titleService: Title
  ) {
    this.navigations = menus;
    this.type = 'theme1';
    this.setBreadcrumb();
  }

  setBreadcrumb() {
    this.route.events.subscribe((router: Event) => {
      if (router instanceof NavigationEnd) {
        const activeLink = router.url;
        const breadcrumbList = this.filterNavigation(this.navigations, activeLink);
        this.navigationList = breadcrumbList;
        const title = breadcrumbList[breadcrumbList.length - 1]?.title || 'Welcome';
        this.titleService.setTitle(title + ' | Able pro Angular Admin Template');
      }
    });
  }


  //edit navigation
  filterNavigation(navItems: NavigationItem[], activeLink: string): titleType[] {
    for (const navItem of navItems) {
      let paramStartIndex =  navItem.url?navItem.url.indexOf(':'):-1
      // console.log('paramStartIndex', paramStartIndex, activeLink)
      let navItemUrl: any = ''
      if(paramStartIndex >= 0){
        let test: any =  navItem.url?.split('/');
        let test2:any =  activeLink?.split('/')
        if(test2.length === test2.length){
          test.pop();
          test2.pop();
          navItemUrl = test.join('/')
          activeLink = test2.join('/')
        }
      }else {
        navItemUrl = navItem.url
      }

      if (navItem.type === 'item' && 'url' in navItem && navItemUrl === activeLink) {
        return [
          {
            url: 'url' in navItem ? navItem.url : false,
            title: navItem.title,
            link: navItem.link,
            breadcrumbs: 'breadcrumbs' in navItem ? navItem.breadcrumbs : true,
            type: navItem.type
          }
        ];
      }
      if ((navItem.type === 'group'
          || navItem.type === 'collapse'
          || navItem.type === 'item'
        ) &&
        'children' in navItem
      ) {
        // eslint-disable-next-line
        const breadcrumbList = this.filterNavigation(navItem.children!, activeLink);
        if (breadcrumbList.length > 0) {
          breadcrumbList.unshift({
            url: 'url' in navItem ? navItem.url : false,
            title: navItem.title,
            link: navItem.link,
            breadcrumbs: 'breadcrumbs' in navItem ? navItem.breadcrumbs : true,
            type: navItem.type
          });
          return breadcrumbList;
        }
      }
    }
    return [];
  }
}
