import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {StorageService} from "./storage.service";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
    const userRole = currentUser.role;
    if (next.data['roles'] && next.data['roles'].indexOf(userRole) === -1) {
      // this.router.navigate(['']);
      return false;
    }
    return true;

  }
}
