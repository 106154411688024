<mat-drawer-container class="drawer-container" [dir]="direction">
  <mat-drawer
    #sidebar
    class="pc-sidebar"
    [mode]="modeValue"
    opened
    *appHideIfNotLoggedIn
    [ngClass]="{
      compact: currentLayout === 'compact',
      horizontal: currentLayout === 'horizontal'}">
    <nav>
      <div class="navbar-wrapper" >
        <app-vertical-menu *ngIf="currentLayout === 'vertical'" [menus]="menus"></app-vertical-menu>
        <app-compact-menu *ngIf="currentLayout === 'compact'" [menus]="menus"></app-compact-menu>
      </div>
    </nav>
  </mat-drawer>
  <div>
      <app-nav-bar [ngClass]="{'horizontal-navbar': currentLayout === 'horizontal'}" style="display: block;height: 75px"></app-nav-bar>

    <div class="app-container">
      <div class="main-body">
        <div class="page-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
<!--    <app-footer></app-footer>-->
  </div>
</mat-drawer-container>

<app-mobile-menu *ngIf="user" [user]="user"></app-mobile-menu>
