import {EventEmitter, Output, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class EventManagerService {
    public loaderShowEmitter: EventEmitter<any>;
    public loaderHideEmitter: EventEmitter<any>;
    public notificationsCountEmmiter: EventEmitter<any>;
    public messagesCountEmmiter: EventEmitter<any>;
    public openChatRoom: EventEmitter<any> = new EventEmitter<any>();
    public updateLayoutEmitter: EventEmitter<any> = new EventEmitter<any>();
    public userDataUpdate: EventEmitter<any>;
    public openTopupModal: EventEmitter<any>;
    public openWithdrawModal: EventEmitter<any>;
    public initialInfoEmitter: EventEmitter<any> = new EventEmitter();
    public openFilterCategoryEmitter: EventEmitter<any> = new EventEmitter();

    @Output() socketEmitter: EventEmitter<any>;

    constructor() {
        this.loaderShowEmitter = new EventEmitter();
        this.notificationsCountEmmiter = new EventEmitter<any>();
        this.messagesCountEmmiter = new EventEmitter<any>();
        this.loaderHideEmitter = new EventEmitter();
        this.userDataUpdate = new EventEmitter();
        this.socketEmitter = new EventEmitter();
        this.openTopupModal = new EventEmitter();
        this.openWithdrawModal = new EventEmitter();
    }



    shiwLoader(): void {
        this.loaderShowEmitter.emit();
    }

    showRoom(data: any): void {
      this.openChatRoom.emit(data);
    }

    initialInfo(data: any): void {
        this.initialInfoEmitter.emit(data)
    }

    hideLoader(): void {
        this.loaderHideEmitter.emit();
    }
    openFilterCategory(): void {
        this.openFilterCategoryEmitter.emit();
    }


}
