import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';

// import { AuthenticationService } from '../services/authentication.service';
import {StorageService} from "../helpers/storage.service";
import {Observable} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import {MeService} from "../services/user/me.service";
import {EventManagerService} from "../helpers/event-manager";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private redirectToAuth: boolean = false;
  constructor(
    private router: Router,
    private meService: MeService,
    private eventManager: EventManagerService,
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = StorageService.getItem(StorageService.statics.token);
    if (next.data['redirectLogin']) {
      this.redirectToAuth = true

    }
    let url = "";
    if (!token) {
      if (this.redirectToAuth){
        this.router.navigate(['/auth/login'])
      }else{
        this.router.navigate(['/buyer-home/announcements'])

      }
      return false;
    }
    let currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
    if(!currentUser){
      StorageService.removeItem(StorageService.statics.token);
      StorageService.removeItem(StorageService.statics.currentUser);
      if (this.redirectToAuth){
        this.router.navigate(['/auth/login'])
      }else{
        this.router.navigate(['/buyer-home/announcements'])

      }
      return false;
    } else if(currentUser.status === 'blocked' || currentUser.status === 'inactive' || currentUser.status === 'deleted'){
      StorageService.removeItem(StorageService.statics.token);
      StorageService.removeItem(StorageService.statics.currentUser);
      alert('your account was not active. Contact support')
      if (this.redirectToAuth){
        this.router.navigate(['/auth/login'])
      }else{
        this.router.navigate(['/buyer-home/announcements'])

      }
      return false;
    }
    this.meService.me().subscribe((data:any) => {
      if(!data.hasError){
        StorageService.setJsonItem(StorageService.statics.profileData, data.data.user);
        StorageService.setJsonItem(StorageService.statics.initialInfo, data.data);
        this.eventManager.notificationsCountEmmiter.emit(data.data.notSeenNotificationCount);
        this.eventManager.messagesCountEmmiter.emit(data.data.notSeenRooms);
      }
    })

    return true;
  }
}
