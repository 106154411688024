
<main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8" style="height: 100vh">
  <div class="text-center">
    <p class="text-base font-semibold text-indigo-600">Проверка</p>
    <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Проверяем пользователя</h1>
    <div class="mt-10 flex items-center justify-center gap-x-6">
<!--      <a [routerLink]="['/']" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Назад</a>-->
<!--      <a  class="text-sm font-semibold text-gray-900">Связяться <span aria-hidden="true">&rarr;</span></a>-->
    </div>
  </div>
</main>
