// angular import
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Router, RouterModule} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";
import {EventManagerService} from "../../../../services/helpers/event-manager";
import {AuthenticationService} from "../../../../services/services/authentication.service";
import {WithdrawService} from "../../../../services/services/transaction/withdraw.service";
import {UserService} from "../../../../services/services/user/user.service";
import {MeService} from "../../../../services/services/user/me.service";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {StorageService} from "../../../../services/helpers/storage.service";
import {UserWithdrawComponent} from "../../../../pages/admin/user/user-list/user-withdraw/user-withdraw.component";
import {UserTopupComponent} from "../../../../pages/admin/user/user-list/user-topup/user-topup.component";
import {CommonModule} from "@angular/common";
import {SharedModule} from "../../../../shared/shared.module";
import {CategoryComponent} from "../../../../shared/components/category/category.component";
import {NavRightGuardComponent} from "../../../admin/toolbar/toolbar-right-guard/toolbar-right-guard.component";
import {FilterComponent} from "../../../../shared/components/filter/filter.component";
import {ScrollableWallComponent} from "../../../../shared/components/scrollable-wall/scrollable-wall.component";
import {FilterCategoryComponent} from "../../../../shared/components/filter-category/filter-category.component";
import {AnnouncementService} from "../../../../services/services/announcement/announcement.service";

@Component({
  selector: 'app-toolbar-right',
  templateUrl: './toolbar-right.component.html',
  standalone: true,
  styleUrls: ['./toolbar-right.component.scss'],
  imports: [CommonModule, SharedModule, RouterModule, CategoryComponent, NavRightGuardComponent, FilterComponent, ScrollableWallComponent, FilterCategoryComponent],
  providers: [AuthenticationService, StorageService, AnnouncementService]
})
export class ToolbarRightComponent implements OnInit {
  // public props
  @Output() HeaderBlur = new EventEmitter();
  userObj:any;
  profileObj:any;
  env = environment;
  user: any;
  // constructor
  public notSeenMessagesCount: any;
  constructor(
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private eventManagerService: EventManagerService,
    private withdrawService: WithdrawService,
    private userService: UserService,
    private meService: MeService,
    private popup: PopupHelper,
    public dialog: MatDialog,
    private router: Router,
    // private eventManager: EventManagerService
  ) {
    translate.setDefaultLang('ru');
    const currentUser = localStorage.getItem('currentUser');
    const profileData = localStorage.getItem(StorageService.statics.profileData);
    if(currentUser){
      this.userObj = JSON.parse(currentUser);
    }
    if(profileData){
      this.profileObj = JSON.parse(profileData);
    }
    this.eventManagerService.userDataUpdate.subscribe((data)=>{
      const currentUser = localStorage.getItem('currentUser');
      if(currentUser){
        this.userObj = JSON.parse(currentUser);
      }
      this.profileObj = data;
    })
  }


  ngOnInit(): void {
    this.eventManagerService.messagesCountEmmiter.subscribe((data:any) => {
      this.notSeenMessagesCount = data || 0;
    })
  }

  // public method
  useLanguage(language: string) {
    this.translate.use(language);
  }

  headerBlur() {
    this.HeaderBlur.emit();
  }

  logout() {
    this.authenticationService.logout().subscribe((res) => {
      if (!res.hasError) {
        StorageService.clear()
        this.router.navigate(['/auth/login'])
      }
    });
  }


  redirectToChat() {
    this.router.navigate(['/messenger'])

  }
  openBalanceDetails(): void {
    const dialogRef = this.dialog.open(UserWithdrawComponent, {
      data: {
        user:this.profileObj
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.provider && (res.accountNumber && res.balance !== 0)) {
        this.withdrawService.newWithdraw({
          "amount": res.balance,
          "provider": res.provider,
          "accountNumber": res.accountNumber
        }).subscribe( e => {
          if (!e.hasError) {
            // // this.popup.printSuccess()
          }
        })
      }
    })
  }  // public method

  openTopup(): void {
    const dialogRef = this.dialog.open(UserTopupComponent, {
      data: {
        user: this.profileObj
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.userId && (res?.balance || res?.balance === 0)) {
        this.userService.updateBlance({balance:res.balance}, res.userId).subscribe( e => {
          if (!e.hasError) {
            // // this.popup.printSuccess()
          }
        })
      }
    })
  }

  changeCustomerRole(role:string) {
    this.meService.updateCustomerRole({"customerRole": role}).subscribe( res => {
      if (!res.hasError) {
        this.userObj.customerRole = role;
        StorageService.setJsonItem(StorageService.statics.currentUser,this.userObj)

        this.meService.me().subscribe((data:any) => {
          if(!data.hasError){
            this.userObj = data.data.user;
            StorageService.setJsonItem(StorageService.statics.profileData, data.data.user);
            StorageService.setJsonItem(StorageService.statics.initialInfo, data.data);
            this.eventManagerService.userDataUpdate.emit(data.data.user)

            this.eventManagerService.notificationsCountEmmiter.emit(data.data.notSeenNotificationCount);
            this.eventManagerService.messagesCountEmmiter.emit(data.data.notSeenRooms);
          }
        })
      }else {
        this.popup.printError(res.message)
      }

    })

  }

}
