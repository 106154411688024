import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundCurrency'
})
export class RoundCurrencyPipe implements PipeTransform {

  transform(value: number, currency: string = ''): unknown {
    switch (currency) {
      case 'rub':
        return Math.floor(value) + "₽";
      case 'usd':
        return "$" + Math.floor(value);
      default:
        return Math.floor(value);
    }
  }
}
