<div *ngIf="open"  class="relative z-10">
  <div class="fixed inset-0  bg-gray-500 opacity-100 bg-opacity-75 transition-opacity ease-in-out duration-500" (click)="setOpen(false)"></div>

  <div class="fixed inset-0 overflow-hidden opacity-100">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10" >
        <div class="pointer-events-auto w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700">
          <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  <div class="pl-2 f-16 f-w-600">{{'category'|translate}}</div>
                </h3>
                <div class="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    (click)="setOpen(false)"
                  >
                    <span class="absolute -inset-2.5">
                      <i class="fa fa-times"></i>
                    </span>

                  </button>
                </div>
              </div>
            </div>
            <div class="relative mt-6 flex-1 px-4 sm:px-6">
              <app-categories-autocomplete  (click)="setOpen(false)"></app-categories-autocomplete>
              <!--*******************************************************************************-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
