import {AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {PopupHelper} from "../../../services/helpers/popup.helper";
import {ActivatedRoute, Router} from "@angular/router";
import {ReviewsService} from "../../../services/services/review/reviews.service";
import {environment} from "../../../../environments/environment";
import {result} from "lodash";

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnChanges{

  @Input() data:any
  @Input() reviewData:any
  // @ViewChild(MatSort) sort: MatSort;

  ratings = 3;
  starCount = 5;
  rating: {rate: string, value: null| number, bufferValue: number, text: string}[] = [
    { rate: "5", value: null, bufferValue: 100, text: "5 Звезд"},
    { rate: "4", value: null, bufferValue: 100, text: "4 Звезд"},
    { rate: "3", value: null, bufferValue: 100, text: "3 Звезд"},
    { rate: "2", value: null, bufferValue: 100, text: "2 Звезд"},
    { rate: "1", value: null, bufferValue: 100, text: "1 Звезд"},
  ];


  constructor(public dialog: MatDialog,
              protected reviewsService:ReviewsService,
              protected popup: PopupHelper,
              protected _route: ActivatedRoute,
              protected _router: Router,
  ){
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reviewData'] && this.reviewData) {
      if(this.data.sellerRating){
        this.data.rate = this.data.sellerRating;
      } else {
        this.data.rate = this.data.buyerRating;
      }
      const result = this.calculateRatingPercentage(this.reviewData?.data);
      for (const key in result) {
         this.rating.findIndex((e) => {if (e.rate === key){e['value'] = result[key]}})
      }
      // this.rating = Object.values(result).map((value) => ({
      //   value: +value,
      //   bufferValue: 100,
      //   text: `${value} Stars`,
      // }));
    }
  }
  onRatingChanged(ratings: number) {
    this.ratings = ratings;
  }


  calculateRatingPercentage = (dataArray: any[]): { [p: number]: number } => {
    const ratingCount: { [key: number]: number } = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };

    dataArray?.forEach(item => {
      const rate = item.rate;
      if (rate >= 1 && rate <= 5) {
        ratingCount[rate]++;
      }
    });

    const totalCount = dataArray?.length;
    const ratingPercentage: { [key: number]: number } = {};

    for (let i = 1; i <= 5; i++) {
      ratingPercentage[i] = (ratingCount[i] / totalCount) * 100;
    }

    // return {
    //   count: ratingCount,
    //   percentage: ratingPercentage,
    // };
    return ratingPercentage
  };







  protected readonly environment = environment;
}
