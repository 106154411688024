import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
// @ts-ignore
import {CustomerRole} from '../../../services/constants'
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MeService} from "../../../services/services/user/me.service";
import {PopupHelper} from "../../../services/helpers/popup.helper";
import {EventManagerService} from "../../../services/helpers/event-manager";
import {StorageService} from "../../../services/helpers/storage.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-customer-role',
  templateUrl: './customer-role.component.html',
  styleUrls: ['./customer-role.component.scss']
})
export class CustomerRoleComponent implements OnInit{
  public customerRole: string;
  private customer: any;
  public customerRoleEntity = CustomerRole

  @Input() fromMenu = false;
  constructor(private meService:MeService,
              private popup: PopupHelper,
              private router: Router,
              private eventManager: EventManagerService,
              @Optional() private dialogRef: MatDialogRef<CustomerRoleComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {
  }
  ngOnInit() {
    this.customer = StorageService.getJsonItem(StorageService.statics.currentUser)
    if (this.customer){
      this.customerRole = this.customer.customerRole;
      this.eventManager.userDataUpdate.subscribe(()=>{
        this.customer = StorageService.getJsonItem(StorageService.statics.currentUser)
        if (this.customer) {
          this.customerRole = this.customer.customerRole;
        }
      });
    }

  }

  changeCustomerRole(role:string) {
    if(role !== this.customerRole){
      this.meService.updateCustomerRole({"customerRole": role}).subscribe( data => {
        if (!data?.hasError) {
          StorageService.setJsonItem(StorageService.statics.currentUser, data.data.loginData);
          StorageService.setItem(StorageService.statics.role, data.data.loginData.role);
          StorageService.setItem(StorageService.statics.token, data.data.loginData.token);
          this.router.navigate(['/']) .then(() => {
            window.location.reload();
          });
        } else {
          this.popup.printError(data.errorMessages);
        }
      })
    }
  }
}
