import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {StorageService} from './storage.service';
import {catchError, map} from 'rxjs/operators';
import {ResponseHelper} from './response.helper';
import {EventManagerService} from './event-manager';
import {Observable, throwError} from 'rxjs';
import {PopupHelper} from "./popup.helper";
import {Router} from "@angular/router";

// import {NotificationsHelper} from './notifications.helper';


@Injectable()
export class NetworkHelper {

    constructor(public http: HttpClient,
                public router: Router,
                public eventManager: EventManagerService,
                public popup: PopupHelper
    ) {
        NetworkHelper.popupHelper = this.popup;
        NetworkHelper.eventManagerHelper = this.eventManager;
    }

    static requestQueue:any = [];
    static popupHelper: any;
    static eventManagerHelper: any;

    public checkQueue() {
        NetworkHelper.requestQueue.push(1);
        if (NetworkHelper.requestQueue.length === 1) {
            // this.eventManager.shiwLoader();
        }
    }

    static prepareHttpOptions(customToken?: string): HttpHeaders {
        const headerObj:any = {};
        if (StorageService.getToken()) {
            headerObj['Authorization'] = StorageService.getToken().toString();
        }

        return new HttpHeaders(headerObj);
    }

    static prepareOptionsFile(customToken?: string): HttpHeaders {
        const headerObj:any = {};
        if (StorageService.getToken()) {
            headerObj['Authorization'] =  StorageService.getToken().toString();
            headerObj['responseType'] = 'text';
        }

        return new HttpHeaders(headerObj);
    }

    static prepareFormDataHttpOptions(customToken?: string): HttpHeaders {
        const headerObj:any = {};
        if (StorageService.getToken()) {
            headerObj['Authorization'] = StorageService.getToken().toString() ;
        }
        headerObj['Content-Type'] = 'multipart/form-data';

        return new HttpHeaders(headerObj);
    }

    getWithoutLoader<T>(url:any, jsonData?:any, customToken?: string): Observable<ResponseHelper> {
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        let params: HttpParams;
        let queryString = '';
        if (jsonData !== null && jsonData !== undefined) {
            queryString = Object.keys(jsonData).map(key => (jsonData[key]) ? key + '=' + jsonData[key] + '&' : '').join('');
        }
        // @ts-ignore
      return this.formatedResponse(this.http.get<any>(url + queryString.slice(0, -1), {headers: headers, params: params}));
    }

    get<T>(url: string, jsonData?: any | null | undefined, customToken?: string): Observable<ResponseHelper> {
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        this.checkQueue();
        let params: HttpParams;
        let queryString = '';
        if (jsonData !== null && jsonData !== undefined) {
            // queryString = Object.keys(jsonData).map(key => (jsonData[key]) ? key + '=' + jsonData[key] + '&' : '').join('');
          queryString = buildQueryString(jsonData)
        }
      // @ts-ignore
      return this.formatedResponse(this.http.get<any>(url + '?' + queryString.slice(0, -1), {headers: headers}));
    }

    post<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
      // @ts-ignore
      return this.formatedResponse(this.http.post<T>(url, data, {headers: headers}));
    }


    postFormData<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareFormDataHttpOptions(customToken);
        // @ts-ignore
        return this.formatedResponse(this.http.post<T>(url, data, {headers: headers}));
    }

    put<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        // @ts-ignore
        return this.formatedResponse(this.http.put<T>(url, data, {headers: headers}));
    }

    patch<T>(url: string, data: any, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        // @ts-ignore
        return this.formatedResponse(this.http.patch<T>(url, data, {headers: headers}));
    }


    downloadFile(url: string, customToken?: string): Observable<any> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareOptionsFile(customToken);
        return this.http.get(url, {headers: headers, responseType: 'text'}).pipe((data) => {
            NetworkHelper.eventManagerHelper.hideLoader();
            return data;
        });
    }

    del<T>(url: string, customToken?: string): Observable<ResponseHelper> {
        this.checkQueue();
        const headers: any = NetworkHelper.prepareHttpOptions(customToken);
        // @ts-ignore
      return this.formatedResponse(this.http.delete<T>(url, {headers: headers}));
    }

    formatedResponse(obj: Observable<any>) {
        // @ts-ignore
      return obj.pipe(catchError(this.handleError)).pipe(map((res: any) => {
            NetworkHelper.requestQueue.splice(0, 1);
            if (NetworkHelper.requestQueue.length === 0) {
                this.eventManager.hideLoader();
            }
            if(res.statusCode === 401) {
                NetworkHelper.popupHelper.printError('Нужно зарегистрироваться');
                localStorage.clear();
                this.router.navigate(['/']);
            } else if(res.statusCode === 200 || res.statusCode === 201){
                return new ResponseHelper(res);
            }  else if(res.statusCode === 422){
              NetworkHelper.popupHelper.printError(res.message);
              return new ResponseHelper(res);
            } else if(res.statusCode === 400){
                return new ResponseHelper(res);
            } else {
                NetworkHelper.popupHelper.printError(res.message);
                return new ResponseHelper(res);
            }

        }));
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
        }
        NetworkHelper.requestQueue.splice(0, 1);
        if (NetworkHelper.requestQueue.length === 0) {
            NetworkHelper.eventManagerHelper.hideLoader();
        }
        NetworkHelper.popupHelper.printError('something went wrong. Please check your network connection');
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}

function buildQueryString(data: any, parentKey = ''): string {
  return Object.keys(data).map(key => {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    if (Array.isArray(data[key])) {
      // Handle array values
      return data[key].map((item: any) => `${fullKey}=${item}&`).join('');
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      // Recursively handle nested objects
      return buildQueryString(data[key], fullKey);
    } else {
      // Handle non-object and non-array values
      return (data[key] !== null && data[key] !== undefined) ? `${fullKey}=${data[key]}&` : '';
    }
  }).join('');
}


