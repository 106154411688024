import { Injectable } from '@angular/core';
import {EventManagerService} from "../../helpers/event-manager";
import {Socket} from "ngx-socket-io";
// @ts-ignore
import {EVENTTYPES} from "../../../services/constants";
import {SocketConnect} from "./socket-connect";

@Injectable({
  providedIn: 'root'
})
export class SocketMainService {


  constructor(public socket: SocketConnect,
              private eventManager: EventManagerService) {
    this.socket.on('connect', () => console.log('Connected'));
    this.socket.on('disconnect', () => {console.log('Disconnect');});
  }


  joinRoom(eventType = EVENTTYPES.USER, forId: any = null){
    this.socket.emit('join', {eventType, forId});
  }

  unJoinRoom(eventType = EVENTTYPES.USER, forId: any = null){
    this.socket.emit('leave', {eventType, forId});
  }

  listenToEventWithCallback(actionType: any, eventType: any, callback:any){
    this.socket.on(actionType +'_' + eventType, callback);
  }

  // listenToEventWithEventManager(actionType: string){
  //   this.socket.on(actionType, (data: any) => {
  //     this.eventManager.socketDataListener.emit(data);
  //   })
  // }

  disconnect() {
    this.socket.disconnect();
  }
}
