// angular import
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// project import
import { AdminComponent } from './admin.component';
import {NavBarModule} from "./toolbar/toolbar.module";
import {SharedModule} from "../../shared/shared.module";
import {VerticalMenuModule} from "./menu/vertical-menu";
import {CompactMenuModule} from "./menu/compact-menu";
import {BreadcrumbComponent} from "./breadcrumb/breadcrumb.component";
import {FooterComponent} from "./footer/footer.component";
import {UserService} from "../../services/services/user/user.service";
import {MobileMenuComponent} from "./menu/mobile-menu/mobile-menu.component";
import {HideIfNotLoggedInDirective} from "../../services/directives/ideIfNotLoggedIn";
import {ScrollableWallComponent} from "../../shared/components/scrollable-wall/scrollable-wall.component";

@NgModule({
  declarations: [AdminComponent],
  imports: [
    CommonModule,
    NavBarModule,
    MobileMenuComponent,
    SharedModule,
    RouterModule,
    VerticalMenuModule,
    CompactMenuModule,
    BreadcrumbComponent,
    FooterComponent,
    HideIfNotLoggedInDirective,
    ScrollableWallComponent
  ],
  providers: [
    UserService
  ]
})
export class AdminModule {}
