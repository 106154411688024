<mat-toolbar
  class="pc-header"
  [ngClass]="{
    'header-blur': HeaderBlur}"
>

<div class="pc-header">
  <div class="header-wrapper {{(currentUser && currentUser.customerRole === 'buyer')?'buyer_nav':'seller_nav'}}">
    <app-filter-category *ngIf="currentUser && currentUser.customerRole === 'buyer'"></app-filter-category>
    <app-nav-left  class="nav-left"></app-nav-left>
    <app-nav-middle></app-nav-middle>
    <app-nav-right (HeaderBlur)="this.HeaderBlur = !this.HeaderBlur" *ngIf="currentUser; else guardNavRight"></app-nav-right>
    <ng-template #guardNavRight>
      <app-nav-right-guard (HeaderBlur)="this.HeaderBlur = !this.HeaderBlur"></app-nav-right-guard>
    </ng-template>
  </div>
</div>
</mat-toolbar>
