import {Injectable} from "@angular/core";
import {NetworkHelper} from "../../helpers/network.helper";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class WithdrawService {
  private getUrl ='withdraw'
  private newWithdrawUrl ='withdraw';
  private changeStatusWithdraw = 'withdraw/:id'
  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {
  }

  getItems(pagination: any, filter: any): Observable<any>{
    return this.networkHelper.get(environment.apiURL + this.getUrl, {...pagination, ...filter});
  }

  newWithdraw(data:any): Observable<any>{
    return this.networkHelper.post(environment.apiURL + this.newWithdrawUrl, data);
  }

  changeWithdrawStatus(id: any, data: any): Observable<any>{
    return this.networkHelper.put(environment.apiURL + this.changeStatusWithdraw.replace(':id', id), data);
  }
}
