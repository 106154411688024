import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ChatService } from '../../../../services/services/chat/chat.service';
import { PopupHelper } from '../../../../services/helpers/popup.helper';
import { StorageService } from '../../../../services/helpers/storage.service';
import { environment } from '../../../../../environments/environment';
import { ACTIONTYPES, EVENTTYPES } from '../../../../services/constants';
import { SocketMainService } from '../../../../services/services/socket/socket-main-service';
import { EventManagerService } from '../../../../services/helpers/event-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { Sortable } from '../../../../services/helpers/sortable';

@Component({
  selector: 'app-message-room',
  templateUrl: './message-room.component.html',
  styleUrls: ['./message-room.component.scss']
})
export class MessageRoomComponent extends Sortable implements OnInit, OnChanges {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('inputMessage') inputMessageRef: ElementRef;

  @Input() toggleButton: boolean = false;
  @Input() type: string = '';    //order  or   announcement
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onToggle: EventEmitter<any> = new EventEmitter();
  @Input() roomId: any;
  public selectedConversation: any;

  public selectedChat: boolean = false;
  public newMessage: any;
  public customUserId: any;
  public chatAccountIndex: any;
  protected readonly environment = environment;

  constructor(private chatService: ChatService,
              private popup: PopupHelper,
              private eventManager: EventManagerService,
              private socketService: SocketMainService,
              protected _route: ActivatedRoute,
              protected _router: Router,
  ) {
    super(chatService,_route, _router, popup);

  }

  ngOnInit() {
    this.eventManager.openChatRoom.subscribe((data: any) => {
      this.roomId = data.selectedConversationId;
      this.getRoom();
    });
    this.customUserId = StorageService.getJsonItem(StorageService.statics.currentUser).user;
    this.socketService.listenToEventWithCallback(ACTIONTYPES.UPDATE,
      EVENTTYPES.CHATROOM, (data: any) => {
        if (data.conversation.id === this.selectedConversation.id) {
          this.selectedConversation.messages.push(data);
          setTimeout(() => {
            this.scrollToBottom();

          }, 500);
        }
      });
  }

  ngOnChanges() {
    this.getRoom();
  }

  toggle() {
    this.onToggle.emit();
  }

  getRoom() {
    if (!this.roomId) {
      return;
    }
    this.chatService.getById(this.roomId, { type: this.type }).subscribe((res) => {
      if (!res.hasError) {
        this.newMessage = null;
        this.selectedConversation = res.data;
        this.selectedChat = true;
        this.chatAccountIndex = this.selectedConversation.accounts.findIndex((e: any) => (+e.id !== +this.customUserId));
        this.scrollToBottom();

      } else {
        this.popup.printError(res.message);
      }
    });
  }

  async scrollToBottom() {
    if (this.scrollContainer && this.scrollContainer.nativeElement) {
      this.scrollContainer.nativeElement.scrollIntoView({ block: 'end' });
    }
  }

  sendMessage() {
    if (this.newMessage && this.newMessage.length) {
      this.chatService.new(this.selectedConversation.id, { content: this.newMessage }).subscribe(res => {
        if (!res.hasError) {
          this.newMessage = '';
          this.getRoom();
        }
      });
    }
  }
  loadMore(){
    if (!this.loading){
      this.loading = true
      this.onNext();
    }
  }
}
