import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// @ts-ignore
import { NotificationType, NotificationStatus } from '../../../services/constants';
import { OrderService } from '../../../services/services/order/order.service';
import { PopupHelper } from '../../../services/helpers/popup.helper';
import { NotificationService } from '../../../services/services/notification.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() item: any;
  @Output() seenEmit: EventEmitter<any> = new EventEmitter();
  @Output() accept: EventEmitter<any> = new EventEmitter();
  @Output() deleteNotification: EventEmitter<any> = new EventEmitter();
  orderId: any;
  public notificationTypes = NotificationType;
  public notificationStatus = NotificationStatus;
  productName: any;
  productImage: any;
  public order: any;

  constructor(private orderService: OrderService,
              private notificationService: NotificationService,
              private popup: PopupHelper) {
  }

  ngOnInit(): void {
    if (this.item.metadata) {
      let meta = JSON.parse(this.item.metadata);
      if (meta.orderId) {
        this.orderId = meta.orderId;
        if (this.item.type === 'request') {
          this.orderService.getById(this.orderId).subscribe(res => {
            if (!res.hasError) {
              this.order = res.data;
            }
          });
        }

      }
    }


    if (this.item.title) {
      let title = JSON.parse(this.item.title);
      if (title.productName) {
        this.productName = title.productName;
      }
      if (title.productImage) {
        this.productImage = title.productImage;
      }
    }
  }

  seen() {

    if (this.item.status === this.notificationStatus.NOT_SEEN.value) {
      this.seenEmit.emit({ id: this.item.id });
    }
  }

  acceptOrder(event: MouseEvent) {
    event.stopPropagation();
    this.orderService.acceptOrder(this.orderId).subscribe((data: any) => {
      if (!data.hasError) {
        // this.popup.printSuccess("Успешно");
        this.seen();

      }
    });
  }

  rejectOrder(event: MouseEvent) {
    event.stopPropagation();
    this.orderService.rejectOrder(this.orderId).subscribe((data: any) => {
      if (!data.hasError) {
        // this.popup.printSuccess("Успешно");
        this.seen();
      }
    });
  }

  delete(id: any, event: any) {
    event.stopPropagation();
    this.notificationService.del(id).subscribe((e) => {
      if (!e.hasError) {
        // this.popup.printSuccess("Уведомление успешно удалено");
        this.deleteNotification.emit(id);

      }
    });

  }
}
