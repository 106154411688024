import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../helpers/network.helper';

@Injectable()
export class NotificationService{


  private getUrl:string='notification';
  private allSeenUrl:string='notification/all-seen';
  private seenUrl:string='notification/seen/:id';
  private delete:string='notification/:id';

  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {
  }


   get(): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUrl);
  }
  getItems(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUrl, {...pagination,...filter});
  }
  allSeen(): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.allSeenUrl,{});
  }
  seen(id:string): Observable<any> {
    return this.networkHelper.put(environment.apiURL + this.seenUrl.replace(':id', id),{});
  }
  del(id:string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.delete.replace(':id', id));
  }
}
