import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-wrapper',
  templateUrl: './image-wrapper.component.html',
  styleUrls: ['./image-wrapper.component.scss']
})
export class ImageWrapperComponent implements OnInit, OnChanges {


  @Input() src: string | null;
  @Input() alt: string | null;
  @Input() name: string | null;
  @Input() avatar: any;
  @Input() updateVariable: number;
  @Input() class: string | null;
  @Input() style: string | null;
  @Input() fontSize: number = 15;
  @Input() backgroundColor: string | null;
  @Input() color: string = "white";
  @Output() imageLoad = new EventEmitter();

  colors: any = [
    {
      backgroundColor: '#9147ff',
      color: 'white'
    },
    {
      backgroundColor: '#ea4435',
      color: 'white'
    },
    {
      backgroundColor: '#1876F2',
      color: 'white'
    },
    {
      backgroundColor: '#fafa1a',
      color: 'black'
    },
    {
      backgroundColor: '#4efa1a',
      color: 'black'
    },
    {
      backgroundColor: '#47d6a6',
      color: 'black'
    },
    {
      backgroundColor: '#d647cf',
      color: 'black'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  init(){
    if(this.avatar && typeof this.avatar === 'string'){
      this.src = this.avatar
    }else if(this.avatar && this.avatar.profilePic){
      this.src = this.avatar.profilePic;
    } else if(this.avatar && (this.avatar.firstName || this.avatar.lastName)){
      this.setAvatarNickname(this.avatar)
    }
  }

  setAvatarNickname(avatar:any){
    const {ctx, canvas} = this.createAvatarContext(this.fontSize * 2, this.fontSize * 2);
    // @ts-ignore
    ctx.font = this.fontSize + "px Arial";
    let name = avatar?.firstName?avatar?.firstName: avatar.lastName
    let charCode = name.charCodeAt(0)
    let colorIndex = charCode%7
    // @ts-ignore
    ctx.fillStyle = this.backgroundColor?this.backgroundColor: this.colors[colorIndex].backgroundColor;
    // @ts-ignore
    ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2);
    // @ts-ignore
    ctx.textAlign = "center";

    // @ts-ignore
    ctx.fillStyle = this.color?this.color: this.colors[colorIndex].color;
    // @ts-ignore
    ctx.fillText(name[0].toUpperCase(), this.fontSize, this.fontSize + this.fontSize/3);

    // @ts-ignore
    this.src = canvas.toDataURL();
  }

  detectError(error: any){
    if(this.avatar && (this.avatar.firstName || this.avatar.lastName)){
      this.setAvatarNickname(this.avatar)
    }
  }

  emitLoad(){
    this.imageLoad.emit()
  }

  createAvatarContext(width = 50, height = 50) {
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return {ctx: canvas.getContext("2d"), canvas};
  }

  ngOnChanges(){
    this.init()
  }


}
