import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-pagination-custom',
  templateUrl: './pagination-custom.component.html',
  styleUrls: ['./pagination-custom.component.scss']
})
export class PaginationCustomComponent implements OnInit, OnChanges{
  @Output() changePage: EventEmitter<any> = new EventEmitter();

  @Input() showPageSizeOptions = true;
  @Input() pageSizeOptions = [100, 200, 500, 1000];

  @Input() hidePageSize = false;
  @Input() pageSize = 100;

  @Input() length = 0;
  @Input() pageIndex = 1;

  @Input() showFirstLastButtons = true;
  @Input() disabled = false;

  pageEvent: PageEvent;
  constructor() {
  }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['pageIndex']) {
      this.pageIndex = this.pageIndex - 1;
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    let data = {
      limit: e.pageSize ,
      totalItems: e.length,
      page: e.pageIndex + 1,
    }
    this.changePage.emit(data);
  }
}
