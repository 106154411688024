import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MessengerRoutingModule} from "./messenger-routing.module";
import {ChatService} from "../../../services/services/chat/chat.service";
import {MessageItemComponent} from './message-item/message-item.component';
import {CardComponent} from "../../../services/components/card/card.component";
import { MessageRoomComponent } from './message-room/message-room.component';
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgScrollbar} from "ngx-scrollbar";
import {MessageRoomsComponent} from "./message-rooms/message-rooms.component";
import {TranslateModule} from "@ngx-translate/core";
import { ScrollableWallComponent } from '../../../shared/components/scrollable-wall/scrollable-wall.component';




@NgModule({
  declarations: [
    MessageItemComponent,
    MessageRoomComponent,
    MessageRoomsComponent
  ],
  imports: [
    CommonModule,
    MessengerRoutingModule,
    CardComponent,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    NgScrollbar,
    TranslateModule,
    ScrollableWallComponent,
  ],
    exports: [
        MessageItemComponent,
        MessageRoomComponent,
        MessageRoomsComponent
    ],
  providers: [
    ChatService
  ]
})
export class MessengerModule { }
