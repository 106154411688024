import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ChatService} from "../../../../services/services/chat/chat.service";
import {PopupHelper} from "../../../../services/helpers/popup.helper";
import {environment} from "../../../../../environments/environment";
import {SocketMainService} from "../../../../services/services/socket/socket-main-service";
import {Sortable} from "../../../../services/helpers/sortable";
import {MatDrawer, MatDrawerMode} from "@angular/material/sidenav";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../../../../services/helpers/storage.service";
import {EventManagerService} from "../../../../services/helpers/event-manager";

@Component({
  selector: 'app-message-rooms',
  templateUrl: './message-rooms.component.html',
  styleUrls: ['./message-rooms.component.scss']
})
export class MessageRoomsComponent extends Sortable implements OnInit{
  @ViewChild('commerce') commerce: MatDrawer;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();
  modeValue: MatDrawerMode = 'side';
  status: string = 'true';
  protected readonly environment = environment;
  public customUserId: any;
  public selectedConversationId: any;


  constructor(public dialog: MatDialog,
              private chatService:ChatService,
              private eventManager: EventManagerService,
              private popup:PopupHelper,
              protected _route: ActivatedRoute,
              protected _router: Router,
  ) {
    super(chatService,  _route, _router, popup)
    this.selectedConversationId =  this.route.snapshot.paramMap.get('id')
  }

  ngOnInit() {
    this.customUserId = StorageService.getJsonItem(StorageService.statics.currentUser).user
    this.filter()
  }
  setConvId(id: any) {
    this.eventManager.showRoom({selectedConversationId: id});
    this.selectedConversationId = id;
    this.notifyParent.emit(id);
  }

  loadMore(){
    if (!this.loading){
      this.loading = true
      this.onNext();
    }
  }

}
