<div class="toolbar-middle">
  <div class="header-button">
  </div>

<div class="search-container-relative">
  <div class="search-container">
    <div class="search-field">
      <mat-form-field appearance="outline" style="width: 100%;">
        <input type="text"   matInput (input)="onInput($event)" placeholder="Поиск" style="width: 80%;">
      </mat-form-field>
    </div>
    <div class="results-field">

      <ul class="suggested-places" [ngClass]="{'open':openComplete}">
        <li  *ngFor="let suggestion of suggestions">
          <a [routerLink]="['buyer-landing-buyer/announcement/details/', suggestion.id]" (click)="closeSearch()">
            <div class="message-img">
              <img [src]="suggestion.product.image" width="100" height="100">
            </div>
            <div>
              <span class="product-title">{{ suggestion.product.name }}</span>
              <div class="can-earn" *ngIf="suggestion.maximalEarning">Заработок: +{{ suggestion.maximalEarning }} руб.</div>
              <div class="can-cashback" *ngIf="suggestion.cashbackAmount" >Кешбек: +{{ suggestion.cashbackAmount }} руб.</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="mask-handler" [class]="{'block':openComplete}" (click)="closeSearch()"></div>
</div>
