<app-card [showHeader]="false" cardClass="bg-accent-100" *ngFor="let item of data.data">
  <div class="flex align-item-start" >
    <div class="chat-avatar">
      <img *ngIf="item?.writer?.profilePic" src="{{ environment.filePath + item.writer.profilePic }}" alt="img" class="img-fluid " />
    </div>
    <div class="flex-grow-1 m-l-10">
      <div class="f-w-600">{{ item.writer.nickName }}</div>
      <div class="text-muted mat-small m-b-0">{{ item.createdAt | date: 'short'  }}</div>
      <app-star-rating [rating]="item.rate" [starCount]="starCount"></app-star-rating>
      <p class="m-b-0 m-t-10">{{ item.title }}</p>
    </div>
  </div>
</app-card>
<!--<button mat-button color="primary" class="more-comment m-b-30">View More Comments</button>-->
