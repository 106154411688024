<!--<ng-container *ngIf="item">-->
<!--  <div *ngIf="in else out" class="message-in">-->
<!--    <div class="flex align-item-start">-->
<!--      <div class="flex-grow-1 m-l-10">-->
<!--        <div class="flex align-item-start flex-column">-->
<!--          <div class="flex mb-4"-->
<!--               [ngClass]="{ 'justify-start': !item,'justify-end': item}" >-->
<!--&lt;!&ndash;            <img *ngIf="!item"&ndash;&gt;-->
<!--&lt;!&ndash;              alt="my profile image"&ndash;&gt;-->
<!--&lt;!&ndash;              class="object-cover h-8 w-8 rounded-full"&ndash;&gt;-->
<!--&lt;!&ndash;              src="{{environment.filePath + item.sender.profilePic}}"/>&ndash;&gt;-->

<!--            <img-->
<!--              *ngIf="item"-->
<!--              alt="opponent profile image"-->
<!--              class="object-cover h-8 w-8 rounded-full"-->
<!--              src="{{environment.filePath + item.sender.profilePic}}"-->
<!--            />-->
<!--            <div-->
<!--              [ngClass]="{ 'bg-blue-400 rounded-bl-3xl mr-2  rounded-tl-3xl': item,'bg-gray-400 rounded-br-3xl ml-2  rounded-tl-3xl': !item}"-->
<!--              class="py-3 px-4   rounded-tr-xl text-white">-->
<!--              {{ item.content }}-->
<!--            </div>-->

<!--          </div>-->
<!--          <p class="mb-1 text-muted"><small>{{ item.createdAt | date:'EEEE, h:mm a' }}</small></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <ng-template #out>-->
<!--    <div class="message-out">-->
<!--      <div class="flex align-item-end flex-column">-->
<!--        <div class="flex mb-4"-->
<!--             [ngClass]="{ 'justify-start': !in, 'justify-end': in}">-->
<!--&lt;!&ndash;          <img&ndash;&gt;-->
<!--&lt;!&ndash;            *ngIf="!item"&ndash;&gt;-->
<!--&lt;!&ndash;            src="{{environment.filePath + item.sender.profilePic}}"&ndash;&gt;-->
<!--&lt;!&ndash;            class="object-cover h-8 w-8 rounded-full"&ndash;&gt;-->
<!--&lt;!&ndash;            alt="my profile image"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--          <div-->
<!--            class="py-3 px-4   rounded-tr-xl text-white"-->
<!--            [ngClass]="{-->
<!--                     'bg-blue-400 rounded-bl-3xl mr-2  rounded-tl-3xl': item,-->
<!--                     'bg-gray-400 rounded-br-3xl ml-2  rounded-tl-3xl': !item-->
<!--    }">-->
<!--            {{item.content}}-->
<!--          </div>-->
<!--          <img-->
<!--            *ngIf="item"-->
<!--            src="{{environment.filePath + item.sender.profilePic}}"-->
<!--            class="object-cover h-8 w-8 rounded-full"-->
<!--            alt="opponent profile image"-->
<!--          />-->
<!--        </div>-->
<!--        <p class="mb-1 text-muted"><small>{{item.createdAt | date:'EEEE, h:mm a'}}</small></p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</ng-container>-->


<div class="flex mb-4"
     [ngClass]="{'justify-start': !in,'justify-end': in}">
  <img *ngIf="!in"
    src="{{environment.filePath + item.sender.profilePic}}"
    class="object-cover h-8 w-8 rounded-full"
    alt="my profile image"
  />
  <div class="py-3 px-4   rounded-tr-xl text-white"
    [ngClass]="{
    'bg-blue-400 rounded-bl-3xl mr-2  rounded-tl-3xl': in,
    'bg-gray-400 rounded-br-3xl ml-2  rounded-tl-3xl': !in }">
    {{item.content}}
    <p>  {{item.createdAt | date:'EEEE, h:mm a'}}
    </p>
  </div>

  <img *ngIf="in"
    src="{{environment.filePath + item.sender.profilePic}}"
    class="object-cover h-8 w-8 rounded-full"
    alt="opponent profile image" />
</div>






