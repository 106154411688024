import {Component, Input} from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-review-item',
  templateUrl: './review-item.component.html',
  styleUrls: ['./review-item.component.scss']
})
export class ReviewItemComponent {
  @Input() data:any

  starCount = 5;


  protected readonly environment = environment;
}
