<ng-container *ngIf="!item.hidden">
  <li [ngClass]="item.classes!" *ngIf="item.url && !item.external" [routerLinkActive]="['active']">
    <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="toggleMenu($event)">
      <span *ngIf="item.icon" class="coded-micon">
        <svg class="pc-icon" *ngIf="!item.iconTag">
          <use attr.xlink:href="assets/fonts/custom-icon.svg{{ item.icon }}"></use>
        </svg>
        <a href="javascript:void(0)" *ngIf="item.iconTag">
          <i class="{{item.icon}}"></i>
        </a>
      </span>
      <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title | translate }}</span>
      <ng-template #directTitle>
        {{ item.title | translate }}
      </ng-template>
    </a>
  </li>
  <li [ngClass]="item.classes!" *ngIf="item.url && item.external">
    <a [target]="item.target ? '_blank' : '_self'" [routerLinkActive]="item.url">
      <span *ngIf="item.icon" class="coded-micon">
        <svg class="pc-icon" *ngIf="!item.iconTag">
          <use attr.xlink:href="assets/fonts/custom-icon.svg{{ item.icon }}"></use>
        </svg>
        <a href="javascript:void(0)" *ngIf="item.iconTag">
          <i class="{{item.icon}}"></i>
        </a>
      </span>
      <span class="coded-mtext" *ngIf="item.icon; else directTitle">{{ item.title | translate }}</span>
      <ng-template #directTitle>
        {{ item.title }}
      </ng-template>
    </a>
  </li>
</ng-container>
