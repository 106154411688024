// angular import
import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';


import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
  styleUrls: ['./message-item.component.scss']
})
export class MessageItemComponent implements OnInit {
  // public props

  @Input() in = false;
  @Input() item :any;
  public userData: any;

  constructor(public dialog: MatDialog,
              private route: ActivatedRoute,
              private location: Location,
  ) {

  }

 ngOnInit() {
   // console.log(this.item)
 }

  onSubmit() {}

  private formatForSendToBackend() {}

  protected readonly environment = environment;
}

export class MessageItemmComponent {
}
