<div [matMenuTriggerFor]="Notification" aria-label="mode" class="header-item" (menuOpened)="onClick()" [attr.isMenuOpen]="isMenuOpen.toString()">
  <a href="javascript:" class="pc-head-link text-white badge-on-button">
    <svg class="pc-icon">
      <use xlink:href="assets/fonts/custom-icon.svg#custom-notification"></use>
    </svg>
    <span *ngIf="notSeenCount" class="notification-badge">{{notSeenCount}}</span>
  </a>
</div>
<section id="notification_modal">
  <mat-menu #Notification="matMenu" xPosition="before" class="dropdown-notification"
            [class.menu-open]="isMenuOpen">
    <div class="dropdown-header flex align-item-center justify-content-between">
      <div class="f-w-600 f-16">Увидомление</div>
      <a href="javascript:" class="mat-body text-primary-500" (click)="allSeen()">Все прочитано</a>
    </div>
    <app-scrollable-wall (load)="loadMore()" [height]="'calc(100vh - 215px)'"  className="with-padding">
      <div class="dropdown-body">
        <div *ngFor="let notif of data" >
          <app-notification-item  [item] = "notif" (seenEmit)="seen($event)" (deleteNotification)="handleDeleteNotification($event)"></app-notification-item>
        </div>
        <!--              <div class="m-b-10 mat-body f-w-500">Today</div>-->

      </div>
    </app-scrollable-wall>
  </mat-menu>

</section>
