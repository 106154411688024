// angular import
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// project import
import { NavBarComponent } from './toolbar.component';
import { NavLeftComponent } from './toolbar-left/toolbar-left.component';
import { NavRightComponent } from './toolbar-right/toolbar-right.component';
import {RouterModule} from "@angular/router";
import {StorageService} from "../../../services/helpers/storage.service";
import {AuthenticationService} from "../../../services/services/authentication.service";
import {SharedModule} from "../../../shared/shared.module";
import {AnnouncementService} from "../../../services/services/announcement/announcement.service";
import {ToolbarMiddleComponent} from "./toolbar-middle/toolbar-middle.component";
import {CategoryComponent} from "../../../shared/components/category/category.component";
import {NavRightGuardComponent} from "./toolbar-right-guard/toolbar-right-guard.component";
import {FilterComponent} from "../../../shared/components/filter/filter.component";
import {ScrollableWallComponent} from "../../../shared/components/scrollable-wall/scrollable-wall.component";
import {FilterCategoryComponent} from "../../../shared/components/filter-category/filter-category.component";

@NgModule({
  declarations: [NavBarComponent, NavLeftComponent, NavRightComponent, ToolbarMiddleComponent],
    imports: [CommonModule, SharedModule, RouterModule, CategoryComponent, NavRightGuardComponent, FilterComponent, ScrollableWallComponent, FilterCategoryComponent],
    exports: [NavBarComponent, NavRightComponent],
  providers: [AuthenticationService, StorageService, AnnouncementService]
})
export class NavBarModule {}
