<a (click)="setConvId(order.id)" *ngFor="let order of data" [ngClass]="{
      'bg-gray-150': order.id === selectedConversationId,
      'bg-gray-50': order.id !== selectedConversationId
      }"
   class="flex flex-row py-4 px-2 items-center border-b-2">
  <div class="w-1/4">
    <img [src]="order.announcement ? order?.announcement?.product?.image : order.order.announcement?.product?.image"
         alt=""
         class="h-12 w-12 flex-none rounded-full ">

  </div>
  <div class="w-full">
    <div class="text-sm font-semibold">
      {{ order.announcement ? order?.announcement?.product?.name : order.order.announcement?.product?.name }}
    </div>
    <span class="text-gray-500">
      {{ order.accounts[0].nickName }}
    </span>
  </div>
</a>


<!--<div class="relative">-->
<!--  <div class="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">-->
<!--    <h3 class="pt-2">{{'messages' | translate}}</h3>-->
<!--  </div>-->
<!--  <ul role="list" class="divide-y divide-gray-100">-->
<!--    <li class="flex gap-x-4 px-3 py-5" *ngFor="let order of data" (click)="setConvId(order.id)" [ngClass]="{-->
<!--      'bg-gray-150': order.id === selectedConversationId,-->
<!--      'bg-gray-50': order.id !== selectedConversationId-->
<!--      }">-->
<!--      <img class="h-12 w-12 flex-none rounded-full "  [src]="order.announcement ? order?.announcement?.product?.image : order.order.announcement?.product?.image" alt="">-->
<!--      <div class="min-w-0">-->
<!--        <p class="text-sm font-semibold leading-6 text-gray-900">{{ order.announcement ? order?.announcement?.product?.name : order.order.announcement?.product?.name }}</p>-->
<!--        <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{order.accounts[0].nickName}}</p>-->
<!--      </div>-->
<!--    </li>-->
<!--  </ul>-->
<!--</div>-->
