import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import {StorageService} from "../helpers/storage.service";

@Directive({
  standalone: true,
  selector: '[appHideIfNotLoggedIn]'
})
export class HideIfNotLoggedInDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) { }

  ngOnInit() {
    let currentUser = StorageService.getJsonItem(StorageService.statics.profileData);
    if (currentUser) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
