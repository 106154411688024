<div class="customer-details-header">
  <div class="flex align-item-center justify-content-between">
    <div class="f-w-600 f-16">Пополнить счет</div>
    <a class="avatar avatar-s modal-close" mat-dialog-close>
      <i class="ti ti-x f-20"></i>
    </a>
  </div>
</div>
<div class="customer-details-card p-20">
  <div class="row">
    <div class="col-lg-13">
      <form (ngSubmit)="save()" >
        <div class="should-be-done-by-topup">
<!--          Это тестовая версия пополнение счета-->
          {{'Replenishments'|translate}}
        </div>
        <mat-form-field appearance="outline" class="w-100 m-t-5" >
          <mat-label>Баланс</mat-label>
          <input type="number" matInput [(ngModel)]="balance" name="balance">
        </mat-form-field>

        <div class="text-end m-t-25">
<!--          <button (click)="close()" class="relative ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 ng-star-inserted">{{'cancel' | translate}}</button>-->
          <button type="submit" class="relative ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ng-star-inserted"> {{'deposit'| translate}}  </button>

        </div>

      </form>
    </div>
  </div>
</div>

