import {StorageService} from './storage.service';


export class ResponseHelper {

    public errorCodeMap = {
        depricatedSession: 1000401,
        //1000020: 'Account is not activated',
    };
    public successCode = [200,201];
    private errorCode = [400,401,404,403,402];
    public errorCode2 = [500];

    public response;
    public messages: any;
    public data: any;
    public errorMessages: string | any[];
    public hasError;

    static errorHandler(error: any) {
    }

    constructor(res: any) {
        this.response = res;
        const currentStatus: number = res.statusCode;
        this.hasError = false;
        switch (currentStatus) {
            case this.inc(this.successCode,currentStatus) :
                this.catchForSuccess(res.data, res.message);
                break;
            case this.inc(this.errorCode,currentStatus) :
                this.catchForError(res.message);
                this.hasError = true;
                break;
            case this.inc(this.errorCode2,currentStatus):
                this.catchForError(res.message);
                this.hasError = true;
                break;
            default:
                break;
        }
        if (this.errorMessages && this.errorMessages.length > 0 ) {
            if (Number(this.errorCode) === this.errorCodeMap.depricatedSession) {
                StorageService.removeItem(StorageService.statics.currentUser);
                StorageService.removeItem(StorageService.statics.role);
                location.reload();
            }
        }
    }

    inc(arr: number[], code: number){
      let param = arr.indexOf(code) === -1
      if (!param) {
        return code
      }else {
        return -1
      }
    }

    catchForSuccess(data: never[] | undefined, message: string | any[] | undefined) {
        if (!data || data === undefined) {
            data = [];
        }
        if (!message || message === undefined) {
            message = [];
        }

        this.data = data;

        if (message.length > 0) {
            this.messages = message;
        }
        this.hasError = false;
    }

    catchForError(messages: any) {
        if (!messages || messages === undefined) {
            messages = {msg: 'Unhandled error'};
        }
        if (Array.isArray(messages)) {
            this.errorMessages = messages[0];
        } else {
            this.errorMessages = messages;
            // this.errorCode = messages.code;
        }
    }


    getMessages() {
        return this.messages;
    }

    getData() {
        return this.data;
    }

    getDataForEntity() {

    }

    getErrorMessages() {
        return this.errorMessages;
    }
}
