import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {NetworkHelper} from '../../helpers/network.helper';

@Injectable()
export class CategoryService {
  private loginUrl= 'category';
  private getByIdUrl = 'category';
  private updateUrl='category/:id';
  private deletedUrl="category/:id";
  private getUrl='category'
  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {}

  getItems(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.loginUrl, {...pagination,...filter});
  }
  getById(id:string,): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.updateUrl.replace(':id',id),);
  }
  update(id:string, data:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateUrl.replace(':id',id),data);
  }
  create(data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.getUrl,data );
  }
  deleted(id:string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deletedUrl.replace(':id',id));
  }
  search(key:string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUrl, {title_en:key});
  }
}
