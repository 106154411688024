<app-card [showHeader]="false" cardClass="notification-card m-b-10" padding="16">
  <div class="flex" (click)="seen()" [ngClass]="{'not-seen':item.status !== 'seen'}"   >
    <span class="status active" *ngIf="item.status === notificationStatus.NOT_SEEN.value"></span>
    <i class="ti ti-trash f-18 icon" style="color: #dc2626" (click)="delete(item.id, $event)"></i>
    <div class="topic-description " style="display: flex">
      <div>
        <img class="img-prod img-notif" src="{{productImage}}" alt="">
      </div>

      <!--      <span class="mat-caption float-end text-muted">15 hour ago</span>-->
      <div style="margin-left: 18px" [routerLink]="['order/management/', orderId]" *ngIf="orderId">
        <p class="m-b-2 mat-body" style="font-size: 12px">
          {{item.content | translate}}
<!--          <span *ngIf="orderId">#{{orderId}}</span>-->
          <br/>
          <a  href="javascript:" class=" hover  mat-body text-primary-500">Пройти к заданию</a>
        </p>
        <div class="m-b-10 mat-body not-info ">{{productName | translate}}</div>
        <button *ngIf="item.type==='request' && orderId && order && order.status === 'requested'" (click)="rejectOrder($event)" class="rounded text-center text-white bg-red-500 mt-1 px-2.5 py-1.5 text-xs font-semibold shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
          <i class="text-white fa fa-times mr-1"></i>
          {{'reject'| translate}}
        </button>
        <button *ngIf="item.type==='request' && orderId && order && order.status === 'requested'" (click)="acceptOrder($event)" class="rounded text-center text-white bg-indigo-500 ml-1 mt-1 px-2.5 py-1.5 text-xs font-semibold shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
          <i class="text-white fa fa-check mr-1"></i>
          {{'accept'| translate}}
        </button>
      </div>
    </div>
  </div>
</app-card>
