import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardComponent} from "../../../services/components/card/card.component";
import {CategoryComponent} from "../category/category.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSidenavModule} from "@angular/material/sidenav";
import {NgScrollbar} from "ngx-scrollbar";
import {MatToolbarModule} from "@angular/material/toolbar";
import {EventManagerService} from "../../../services/helpers/event-manager";
import {ScrollableWallComponent} from "../scrollable-wall/scrollable-wall.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-filter-category',
  standalone: true,
  imports: [CommonModule, CardComponent, CategoryComponent, MatButtonModule, MatIconModule, MatSidenavModule, NgScrollbar, MatToolbarModule, ScrollableWallComponent],
  templateUrl: './filter-category.component.html',
  styleUrls: ['./filter-category.component.scss']
})
export class FilterCategoryComponent implements OnInit{
  @ViewChild('drawer') drawer: { toggle: () => void; };
  protected isOpenedFilter: boolean = false;
  HeaderBlur: boolean;

  constructor(private eventManagerService:EventManagerService,
              private router: Router,
              private route: ActivatedRoute,
              ) {
    this.route.queryParams
      .subscribe((params: any) => {
        console.log(params.eventSource)

    })
  }

  ngOnInit() {
    this.eventManagerService.openFilterCategoryEmitter.subscribe(() => {
      this.headerBlur(this.drawer)
    })
  }

  headerBlur(dr: { toggle: () => void; }) {
    dr.toggle()
    this.isOpenedFilter = !this.isOpenedFilter
  }
  selectCategory(event: any) {
    this.input(event.event, event.filterOperator, event.operators)
  }
  input(event: any, filterOperator: any, operators: string[]) {
    this.router.navigate(['/buyer-home/announcements'],{queryParams:{'eventChecked':event.checked,'eventSource':event.source.value, 'filterOperator':filterOperator,'operators':operators}})
  }

  loadMore() {

  }

  resetFilter() {

  }
}
