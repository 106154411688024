<ng-container *ngIf="!item.hidden">
  <li class="nav-item coded-menu-caption ">
    <label>{{ item.title | translate }}</label>
  </li>
  <ng-container *ngFor="let item of item.children">
    <ng-container *ngIf="!item.roles || item.roles.indexOf(userRole) !== -1 || item.roles.indexOf(clientType)!==-1">
      <app-menu-group-vertical *ngIf="item.type === 'group'" [userRole]="userRole" [clientType]="clientType"  [item]="item"></app-menu-group-vertical>
      <app-menu-collapse *ngIf="item.type === 'collapse'" [userRole]="userRole" [clientType]="clientType"  [item]="item"></app-menu-collapse>
      <app-menu-item *ngIf="item.type === 'item'" [userRole]="userRole" [clientType]="clientType"  [item]="item"></app-menu-item>
    </ng-container>
  </ng-container>
</ng-container>
