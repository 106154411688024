// angular import
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {SocketMainService} from "./services/services/socket/socket-main-service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  // public props
  isSpinnerVisible = true;

  // constructor
  constructor(private router: Router, private translate: TranslateService,
              private socketService: SocketMainService,) {
    this.translate.setDefaultLang('ru');
    this.translate.use('ru');
  }

  ngOnInit() {


    //todo dopen coment
    // StorageService.removeItem(StorageService.statics.reginfo)

    // Use ngOnInit instead of ngAfterViewInit
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          setTimeout(() => {
            this.isSpinnerVisible = true;
          });
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
          setTimeout(() => {
            this.isSpinnerVisible = false;
          });
        }
      },
      () => {
        setTimeout(() => {
          this.isSpinnerVisible = false;
        });
      }
    );
  }
}
