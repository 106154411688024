import { Component, EventEmitter, Input, Output } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ChatService} from "../../../services/services/chat/chat.service";
import {environment} from "../../../../environments/environment";
import {PopupHelper} from "../../../services/helpers/popup.helper";
import {ActivatedRoute, Router} from "@angular/router";
import { Sortable } from '../../../services/helpers/sortable';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent extends Sortable {
  @Input() toggleButton: boolean = false;
  @Input() type: string = '';    //order  or   announcement
  @Output() onToggle: EventEmitter<any> = new EventEmitter();
  protected readonly environment = environment;
  public selectedConversationId: any;

  constructor(public dialog: MatDialog,
              private chatService:ChatService,
              private popup:PopupHelper,
              protected _route: ActivatedRoute,
              protected _router: Router,
              ) {
    super(chatService,_route, _router, popup);
  }

  setChatId( id: any ){
    this.selectedConversationId = id;
  }

  loadMore(){
    if (!this.loading){
      this.loading = true
      this.onNext();
    }
  }
}
