import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatChipListboxChange} from "@angular/material/chips";

@Component({
  selector: 'app-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.scss']
})
export class ChipAutocompleteComponent implements OnInit {
  @Input() useModel = false;
  @Input() formControlItem: FormControl = new FormControl<any>('');
  @Input() label: any;
  @Input() callToBackendFunction: any;
  @Output() sendToParent: EventEmitter<any>;
  @Input() class: string;

  public data: any[]

  constructor() {
    this.sendToParent = new EventEmitter<any>();
  }

  ngOnInit() {
    this.change('');
  }

  change(searchKey = '') {
    this.callToBackendFunction.search(searchKey).subscribe((data: any) => {
      if (data.data.data) {
        this.data = data.data.data
        // console.log('data', this.data)
      }
    })
  }

  modelChange(event: MatChipListboxChange) {
    this.sendToParent.emit(event);
  }
}

