import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-click-view',
  templateUrl: './click-view.component.html',
  styleUrls: ['./click-view.component.scss']
})
export class ClickViewComponent {
  @Input() let:any;
}
