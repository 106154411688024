import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {StorageService} from '../helpers/storage.service';

@Injectable()
export class NotAuthGuard implements CanActivate {

    constructor(private router: Router) {


    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const token = StorageService.getItem(StorageService.statics.token);

        if (token) {
            this.router.navigate(['/']);
            return false;
        }
        // not logged in so redirect to login page with the return url
        return true;
    }
}
