import {Injectable} from "@angular/core";
import {NetworkHelper} from "../../helpers/network.helper";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";

@Injectable()
export class ReviewsService {

  private getUrl= 'review';
  private getByIdUrl = 'review/:id';
  private updateUrl='review/:id';
  private deletedUrl='review/:id';

  constructor(private networkHelper: NetworkHelper,
              public http: HttpClient) {}

  getItems(pagination: any,filter:any): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getUrl, {...pagination,...filter});
  }
  getById(id:string): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.getByIdUrl.replace(':id',id));
  }
  create(data:any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.getUrl,data );
  }
  update(id:string, data:any): Observable<any> {
    return this.networkHelper.patch(environment.apiURL + this.updateUrl.replace(':id',id),data);
  }
  deleted(id:string): Observable<any> {
    return this.networkHelper.del(environment.apiURL + this.deletedUrl.replace(':id',id));
  }
}
