import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../../services/services/notification.service";
import {PopupHelper} from "../../../services/helpers/popup.helper";
import {SocketMainService} from "../../../services/services/socket/socket-main-service";
import {EventManagerService} from "../../../services/helpers/event-manager";
import {ActivatedRoute, Router} from "@angular/router";
import {Sortable} from "../../../services/helpers/sortable";
import {NotificationStatus} from "../../../services/constants";

@Component({
  selector: 'app-notifications-widget',
  templateUrl: './notifications-widget.component.html',
  styleUrls: ['./notifications-widget.component.scss'],
})
export class NotificationsWidgetComponent extends Sortable implements OnInit{
  // public data: any;
  public notSeenCount: any;
  isMenuOpen: boolean = false;

  constructor(private notificationService:NotificationService,
              private eventService: EventManagerService,
              private socketService: SocketMainService,
              private popup: PopupHelper,
              protected _route: ActivatedRoute,
              protected _router: Router,
             ) {
    super(notificationService,  _route, _router, popup)
  }
  ngOnInit() {
    this.eventService.notificationsCountEmmiter.subscribe((data:any) => {
      this.notSeenCount = data || 0;
    })
    this.socketService.listenToEventWithCallback('ADD','notification', (event:any)=>{
      this.notSeenCount++;
    })

  }

  onClick() {
    this.filter()
    // this.notificationService.get().subscribe((res) => {
    //   if (!res.hasError){
    //     this.data = res.data.data
    //     this.isMenuOpen = true;
    //   }
    // })
    this.isMenuOpen= true
  }

  allSeen() {
    this.notificationService.allSeen().subscribe((res) => {
      if (!res.hasError){

        this.notSeenCount = 0;
      }
    })
  }

  seen(event: any) {
    this.notificationService.seen(event.id).subscribe((res) => {
      if (!res.hasError){
        this.notSeenCount --;
        this.data = this.data.map((item: any) => {
          if (item.id === event.id) {
            return { ...item, status: NotificationStatus.SEEN};
          }
          return item;
        });
      }
    })
  }
  onMenuClosed() {
    this.isMenuOpen = false;
  }

  handleDeleteNotification(event: number) {
    this.data = this.data.filter((item: any) => item.id !== event);
  }

  loadMore(){
    if (!this.loading){
      this.loading = true
      this.onNext();
    }
  }
}
