import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { NetworkHelper } from '../helpers/network.helper';

@Injectable()
export class AuthenticationService {
  private loginUrl = 'auth/login';
  private registerUrl = 'auth/register';
  private logoutUrl = 'auth/logout';
  private activateCodeUrl = 'auth/activate-code';
  private sendActivationCodeUrl = 'auth/send-activation-code';
  private forgotPasswordUrl = 'auth/forgot-password';
  private resetPasswordUrl = 'auth/reset-password';

  // private getTokenUrl= 'auth/send-user-activation-token';
  // private forgotPasswordStep1 = 'auth/forgot-step-1';
  // private forgotPasswordStep2 = 'auth/forgot-step-2';

  constructor(
    private networkHelper: NetworkHelper,
    public http: HttpClient
  ) {}

  login(loginData: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.loginUrl, loginData);
  }
  register(loginData: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.registerUrl, loginData);
  }
  activateCode(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.activateCodeUrl, data);
  }
  sendActivationCode(data: any): Observable<any> {
    return this.networkHelper.post(environment.apiURL + this.sendActivationCodeUrl, data);
  }

  logout(): Observable<any> {
    return this.networkHelper.get(environment.apiURL + this.logoutUrl);
  }
  // getToken(id: string): Observable<any> {
  //     return this.networkHelper.post(environment.apiURL + this.getTokenUrl, id);
  // }
  forgotPassword(forgotPasswordData: any): any {
    if (forgotPasswordData.token) {
      return this.networkHelper.post<any>(environment.apiURL + this.resetPasswordUrl, forgotPasswordData);
    }
    return this.networkHelper.post<any>(environment.apiURL + this.forgotPasswordUrl, forgotPasswordData);
  }
}
