import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule,} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {NarikCustomValidatorsModule} from "@narik/custom-validators";
import {RouterLink} from "@angular/router";
import {CategoryComponent} from "../category/category.component";
import {EventManagerService} from "../../../services/helpers/event-manager";
import {CategoriesComponent} from "../categories-auto/categories.component";

@Component({
  selector: 'app-side-bar-categories',
  templateUrl: './side-bar-categories.component.html',
  standalone: true,
  imports: [CommonModule, SharedModule, NarikCustomValidatorsModule, RouterLink, CategoryComponent, CategoriesComponent],
  providers: []
})

export class SideBarCategoriesComponent {
  @Input() open: boolean = false;
  @Input() title: string = '';


  protected isOpenedFilter: boolean = false;
  HeaderBlur: boolean;

  constructor(private eventManagerService:EventManagerService) {
  }

  ngOnInit() {
    this.eventManagerService.openFilterCategoryEmitter.subscribe(() => {
      this.setOpen(true);
    })
  }

  selectCategory(event: any) {
    this.input(event.filterOperator, event.operators)
  }
  input(filterOperator: any, operators: string[]) {
    console.log(filterOperator, operators);
  }

  setOpen(value: boolean): void {
    this.open = value;
  }

  applyCategories(){

  }
}
