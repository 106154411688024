import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {Socket} from 'ngx-socket-io';
import {environment} from "../../../../environments/environment";
import {StorageService} from "../../helpers/storage.service";

@Injectable({
  providedIn: 'root'
})
export class SocketConnect extends Socket {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    super( { url: environment.socketUrl, options: {query: {
          token: StorageService.getToken()
        }} });
  }

  override disconnect(_close?: any): any {
    return super.disconnect(_close);
  }

  override connect(): any {
    return  super.connect();
  }

  public checkConnection(){
    return super.connect();
  }
}
