import {Injectable} from '@angular/core';
import Swal from "sweetalert2";
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class PopupHelper {

    private translatable = {
        successTitle: 'success',
        errorTitle: 'error',
        infoTitle: 'infoTitle',
        okButton: 'ok',
        yesButton: 'yes',
        noButton: 'no',
        successType: 'success',
        confirmTitle: 'confirm',
        errorType: 'error',
        infoType: 'info'
    };

    constructor(private translate: TranslateService) {
        for (const key in this.translatable) {
            // @ts-ignore
          this.translate.get(  this.translatable[key]).subscribe((res: string) => {
                // @ts-ignore
            this.translatable[key] = res;
            });
        }
    }

    open(type: string, text: string) {
        let response = null;
        switch (type) {
            case this.translatable.errorType:
                response = this.printError(text);
                break;
            case this.translatable.successType:
                response = this.printSuccess(text);
                break;
            case this.translatable.infoType:
                response = this.printInfo(text);
                break;
        }
    }

    printError(text: string) {
        return Swal.fire({
            title: this.translate.instant(this.translatable.errorTitle),
            text:this.translate.instant(text),
            icon: 'error',
            confirmButtonText: this.translate.instant('оk'),
            allowOutsideClick: true
        });
    }

    printSuccess(text: string = 'successfully') {
        return Swal.fire({
            title: this.translate.instant(this.translatable.successTitle),
            text,
            icon: 'success',
            confirmButtonText: this.translate.instant(this.translatable.okButton),
            allowOutsideClick: true
        });
    }

    printInfo(text: string) {
        return Swal.fire({
            title: this.translate.instant(this.translatable.infoTitle),
            text,
            icon: 'info',
            confirmButtonText: this.translate.instant(this.translatable.okButton),
            allowOutsideClick: true
        });
    }

    confirmationPopup(text: any, callBack: any) {
        return Swal.fire({
                    title: this.translate.instant(this.translatable.confirmTitle),
                    text: text,
                    icon: 'question',
                    confirmButtonText: this.translate.instant(this.translatable.yesButton),
                    cancelButtonText: this.translate.instant(this.translatable.noButton),
                    allowOutsideClick: true,
                    showConfirmButton: true,
                    showCancelButton: true
        }).then((result) => {
          if(callBack){
            callBack(result)
          }
        });
    }

}
