import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    styleUrls: ['./input-autocomplete.component.scss'],
})
export class InputAutocompleteComponent implements OnInit{
    @Input() useModel = false;
    @Input() formControlItem: FormControl;
    @Input() label: any;
    @Input() callToBackendFunction: any;
    @Input() placeholder: any
    @Input() styles: any
    @Output() sendToParent: EventEmitter<any>;
    @Input() errorMessage = '';
    @Input() name = '';
    @Input() id = '';
    @Input() disabled = false;
    @Input() selectBoxOptions:any[] = [];
    @Input() setedItem:any;
    @Input() itemModel: any;
    @Input() class :string;
    @Input() andSelectName :boolean = false;
    @Input()type: string = 'select';



  constructor() {
        this.sendToParent = new EventEmitter<any>();
    }

    ngOnInit() {
        this.change('');
    }

    modelChange(data:any) {
        this.sendToParent.emit(data);
    }

  change(searchKey = ''){
    // if(this.timeOut){
    //   clearTimeout(this.timeOut);
    //   this.timeOut = null;
    // }
    // this.timeOut = setTimeout(() => {
    //   if(this.requestSend){
    //     this.requestSend.unsubscribe();
    //     this.requestSend = null;
    //   }
    this.callToBackendFunction.search(searchKey).subscribe( (item:any) => {
      this.selectBoxOptions = this.setedItem ? [this.setedItem] : [];
      let data = item.data.data
      if(data){
        for(let key in data ){
          const currentItem = data[key];
          if((this.setedItem && this.setedItem.value !== currentItem.id) || !this.setedItem) {
            this.selectBoxOptions.push({
              label: currentItem.name || currentItem.firstName || currentItem.email ||currentItem.title_ru || currentItem.title_en,
              value: this.andSelectName?{id:currentItem.id, name: currentItem.name || currentItem.title_ru || currentItem.title_en} : currentItem.id
            });
          }else if (this.setedItem && this.setedItem.value !== currentItem.id){
            this.selectBoxOptions[0] = {
              label: currentItem.name || currentItem.firstName || currentItem.email ||currentItem.title_en ,
              value: this.selectBoxOptions[0].label
            };
          }
        }
      }

      // this.requestSend = null;
    })
    // this.timeOut = null;
    // }, 500)
  }
}
