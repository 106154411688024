// angular import
import {Component, OnInit} from '@angular/core';
import {StorageService} from "../../../services/helpers/storage.service";
import {MeService} from "../../../services/services/user/me.service";
import {EventManagerService} from "../../../services/helpers/event-manager";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class NavBarComponent implements OnInit{
  // public props
  HeaderBlur: boolean;
  user: any;
  clientType: any;
  public currentUser: any;
  constructor(private meService: MeService, private eventManager: EventManagerService) {
  }

  ngOnInit(): void {
    this.currentUser = StorageService.getJsonItem(StorageService.statics.currentUser)
    if (this.currentUser){
      this.eventManager.userDataUpdate.subscribe(()=>{
        this.currentUser = StorageService.getJsonItem(StorageService.statics.currentUser);
      })
      this.meService.me().subscribe((data:any) => {
        if(!data.hasError){
          StorageService.setJsonItem(StorageService.statics.profileData, data.data.user)
          StorageService.setJsonItem(StorageService.statics.initialInfo, data.data);
          this.eventManager.notificationsCountEmmiter.emit(data.data.notSeenNotificationCount);
          this.eventManager.messagesCountEmmiter.emit(data.data.notSeenRooms);
          this.eventManager.userDataUpdate.emit(data.data.user);
          this.user = data.data.user;
          if(data?.data?.role !== 'admin'){
            this.clientType  = this.user.customerRole;
            this.eventManager.userDataUpdate.emit(data.data.user)
          }
        }
      })
    }

  }
}
