<span class="isolate rounded-md shadow-sm grid grid-cols-2 w-full">
  <button type="button" (click)="changeCustomerRole(customerRoleEntity.BUYER.value)"
          class="relative items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
    [ngClass]="{
      'bg-indigo-600 hover:bg-indigo-500  text-white': customerRole === customerRoleEntity.BUYER.value,
       'bg-white hover:bg-gray-50': customerRole !== customerRoleEntity.BUYER.value
    }"
  >{{'buyer'| translate}}</button>
  <button type="button" (click)="changeCustomerRole(customerRoleEntity.SELLER.value)"
          class="relative -ml-px items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
          [ngClass]="{
            'bg-indigo-600 hover:bg-indigo-500 text-white' : customerRole === customerRoleEntity.SELLER.value,
            'bg-white hover:bg-gray-50': customerRole !== customerRoleEntity.SELLER.value
          }"
  >{{'seller' | translate}}</button>
</span>
