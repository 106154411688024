<div class="container mx-auto shadow-lg rounded-lg">
  <app-chat-header></app-chat-header>
  <div class="flex flex-row justify-between bg-white">
    <div class="hidden md:flex flex-col w-2/5 border-r-2 overflow-y-auto"
      [ngClass]="{'block col-span-8': !selectedConversationId,'hidden': selectedConversationId}">
        <app-message-rooms (notifyParent)="setChatId($event)"></app-message-rooms>
    </div>
    <div class="w-full md:px-5 flex flex-col justify-between"
      [ngClass]="{'hidden': !selectedConversationId, 'block col-span-8': selectedConversationId }">
        <app-message-room (onToggle)="setChatId(null)" [toggleButton]="true"></app-message-room>
    </div>
  </div>
</div>












<!--<div class="m-auto p-3" >-->
<!--  <div class="grid grid-cols-8 w-full h-2/3">-->
<!--    <div class="xl:col-span-2 lg:col-span-3 md:col-span-3 lg:block xl:block md:block bg-white" [ngClass]="{-->
<!--      'block col-span-8': !selectedConversationId,-->
<!--      'hidden': selectedConversationId-->
<!--    }">-->
<!--      <app-message-rooms (notifyParent)="setChatId($event)"></app-message-rooms>-->
<!--    </div>-->
<!--    <div class="xl:col-span-6 lg:col-span-5 md:col-span-5 col-span-8 md:block lg:block xl:block" [ngClass]="{-->
<!--      'hidden': !selectedConversationId,-->
<!--      'block col-span-8': selectedConversationId-->
<!--    }">-->
<!--      <app-message-room-->
<!--        (onToggle)="setChatId(null)"-->
<!--        [toggleButton] = "true"-->
<!--      ></app-message-room>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->





